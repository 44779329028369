import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BootstrapFormGroupComponent } from '../../components/bootstrap/form-group/bootstrap-form-group.component';
import { NgxSelectModule } from '../shared/ngx-select.module';
import { FormGroupModule } from '../shared/form-group.module';
import { ReactiveFormsModule } from '@angular/forms';
import { BootstrapSelectComponent } from '../../components/bootstrap/select/bootstrap-select.component';
import { BootstrapFormControlComponent } from '../../components/bootstrap/form-control/bootstrap-form-control.component';

@NgModule({
    imports: [
        CommonModule,
        NgxSelectModule,
        FormGroupModule,
        ReactiveFormsModule,
    ],
    declarations: [
        BootstrapFormGroupComponent,
        BootstrapSelectComponent,
        BootstrapFormControlComponent,
    ],
    exports: [
        CommonModule,
        NgxSelectModule,
        FormGroupModule,
        ReactiveFormsModule,
        BootstrapFormGroupComponent,
        BootstrapSelectComponent,
        BootstrapFormControlComponent,
    ]
})
export class BootstrapFormsModule {

}
