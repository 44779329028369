<div
    bsModal
    #loginModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="loginModal"
    aria-hidden="true"
    [config]="{backdrop: 'static', keyboard: 'Disable'}"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header text-center">
                <h3 class="modal-title">Login</h3>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-8 col-md-offset-2 text-center">
                        <p>
                            Your session has expired, please enter your password
                            below to continue.
                        </p>

                        <form
                            novalidate
                            (ngSubmit)="login(loginForm)"
                            #loginForm="ngForm"
                        >
                            <div
                                class="form-group"
                                [ngClass]="{'has-error': ! passwordControl.valid && (passwordControl.touched || loginForm.submitted)}"
                            >
                                <label for="password" class="col-form-label"
                                    >Password</label
                                >
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    class="form-control"
                                    placeholder="Password"
                                    [(ngModel)]="password"
                                    required
                                    #passwordControl="ngModel"
                                />

                                <p
                                    class="help-block"
                                    *ngIf="! passwordControl.valid && (passwordControl.touched || loginForm.submitted)"
                                >
                                    The password field is required.
                                </p>
                            </div>

                            <button class="btn btn-primary">Login</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
