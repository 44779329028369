import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {Pipe} from '../../types/Pipe';
import {Pipes} from '../../types/Pipes';

@Injectable()
export class Renderer {
    /**
     * The regex's used in parsing.
     * @var {object} regex
     */
    protected regex = {
        main: /{{ *"((?:\\"|[^"])*)" *((?:\| *[a-zA-Z_$][\w$]*(?:: *(?:"(?:\\"|[^"])*" *,?)*)?)*) *}}/g,
        trimPipe: /(^ *\| *| *\|? *$)/g,
    };

    /**
     * The pipes that will be handled.
     * @var {Pipes} pipes
     */
    protected pipes: Pipes = {
        daysUntil: (format: string, str: string): string => {
            const date = moment(str, format).startOf('day');

            const days = date.diff(moment().startOf('day'), 'days');

            return `${days} day${days === 1 ? '' : 's'}`;
        },
    };

    /**
     * Adds a pipe to the pipes object.
     *
     * @param {string} pipeName The name of the pipe.
     * @param {Pipe} handler The handle function for the pipe.
     */
    public addPipe (pipeName: string, handler: Pipe): void {
        this.pipes[pipeName] = handler;
    }

    /**
     * Gets a pipe by name.
     *
     * @param {string} pipeName The name of the pipe to get.
     * @return {Pipe} The pipe function
     * @throws {Error}
     */
    public getPipe (pipeName: string): Pipe {
        if (this.pipes[pipeName] && typeof this.pipes[pipeName] === 'function') {
            return this.pipes[pipeName];
        }

        throw new Error(`Could not get pipe '${pipeName}'. Either it is not defined or is not a function.`);
    }

    /**
     * Parses a given string by detecting all tokens,
     * and running each pipe on the variable from left
     * to right. Each pipe receives the return value of
     * the last pipe before it.
     *
     * @param {string} body The string to parse
     * @return {string} The parsed string
     */
    public parse (body: string): string {
        // Array of replacements to run
        const replacements = [];

        let match;
        while (match = this.regex.main.exec(body)) {
            // Get the token we are replacing, the variable being
            // affected, and the pipes to apply
            let [token, variable, pipes] = match;

            // trim any whitespace or pipeline characters from the pipes
            // and split the string into an array of characters
            pipes = pipes.replace(this.regex.trimPipe, '').split('');

            // pipes to apply
            const pipesToApply = [];

            // The name of the current pipe
            let pipeName = '';
            // The params to pass to the pipe
            let params = [];

            // The current parameter
            let curParam = '';

            // Flag to determine if we're done getting the pipe name
            let doneWithName = false;
            // Flag to determine if we're currently inside quotes
            let inQuote = false;
            // flag to determine if the current flag has parameters
            let hasParams = false;

            // Loop through every character in the pipes
            for (let i = 0; i <= pipes.length; i++) {
                // Current character, defaults to '|' because the
                // loop continues 1 more time after the end of the string.
                // A pipe character functions as a terminator.
                const c = pipes[i] === undefined ? '|' : pipes[i];
                // Previous character or empty
                const pc = i ? pipes[i - 1] : '';

                // If we haven't dont getting the pipe name
                if (!doneWithName) {
                    // if the character isn't a colon or pipe
                    if (c !== ':' && c !== '|') {
                        // append the character to the pipe name
                        pipeName += c;
                    } else {
                        // otherwise, we have the name
                        doneWithName = true;
                        // If the character is a colon, then we have params
                        hasParams = c === ':';

                        // If we are the end, store what we have
                        // and reset everything.
                        if (i >= pipes.length) {
                            pipesToApply.push([pipeName, params]);
                            pipeName = '';
                            params = [];
                            curParam = '';
                            doneWithName = false;
                            inQuote = false;
                            hasParams = false;
                        }
                    }

                    // skip the rest of the for loop
                    continue;
                }

                // If we need to handle params
                if (hasParams) {
                    // If a quote is starting/ending
                    if (c === '"' && pc !== '\\') {
                        // If we're currently in a quote
                        if (inQuote) {
                            // save the current parameter
                            params.push(curParam);
                            // reset the temp variable
                            curParam = '';
                        }

                        // Toggle inQuote
                        inQuote = !inQuote;

                        // Skip the rest of the loop
                        continue;
                    }

                    // If we're in a quote
                    if (inQuote) {
                        // Append to curParam
                        curParam += c;
                        // Skip the rest of the loop
                        continue;
                    }
                }

                // If we're at a pipe, we can safely assume
                // that it is the terminator, otherwise
                // this code would not be reached, so
                // we save what we have and reset.
                if (c === '|') {
                    pipesToApply.push([pipeName, params]);
                    pipeName = '';
                    params = [];
                    curParam = '';
                    doneWithName = false;
                    inQuote = false;
                    hasParams = false;
                }
            }

            // Loop through every pipe and apply it to the variable
            pipesToApply.forEach(([pipe, parameters]) => {
                const handler = this.getPipe(pipe);
                parameters.push(variable);
                variable = handler(...parameters);
            });

            // Add the token and the variable to the replacements array
            replacements.push([token, variable]);
        }

        // Loop through each replacement and run it agains the body
        replacements.forEach(([find, replace]) => {
            body = body.replace(find, replace);
        });

        // Return the body now all replacements have been run.
        return body;
    }
}
