import { Provider } from '@angular/core';

export class WindowRef {
    /**
     * Get the browser window instance.
     */
    public getNativeWindow(): any {
        return window;
    }
}

export class DocumentRef {
    /**
     * Get the browser document instance.
     */
    public getNativeDocument(): any {
        return document;
    }
}

export const BROWSER_GLOBAL_PROVIDERS: Provider[] = [WindowRef, DocumentRef];