// Lib
import { Injectable } from '@angular/core';

// Service
import { ApiService } from '../api-service';

// Contracts, Models & Types
import { ClientTypeCategory } from '../../models/client-type-category';
import { GdprQuestionsResponse } from '../../types/GDPR/GdprQuestionsResponse';
import { Questions } from '../../types/GDPR/Questions';

// Helpers
import { chunk } from '../../helpers/arrays';

@Injectable()
export class GdprService {
    /**
     * Constructs the class
     *
     * @param {ApiService} api
     */
    constructor (protected api: ApiService) {}

    /**
     * Builds a signed route from the given route and signature.
     *
     * @param {string} route
     * @param {string} signature
     *
     * @return {string}
     */
    protected signedRoute (route: string, signature: string): string {
        return this.api.buildUrl({
            path: route,
            query: { signature },
        });
    }

    /**
     * Gets the questions to ask the user, and the allowed options.
     *
     * @return {Promise<Questions>}
     */
    public getQuestions (): Promise<Questions> {
        return this.api.get('gdpr/questions')
            .toPromise()
            .then((categories: GdprQuestionsResponse) => {
                const questions = {};

                Object.keys(categories).forEach((category) => {
                    const categoryQuestion = categories[category];
                    
                    questions[category] = {
                        question: categoryQuestion.question,
                        options: chunk(categoryQuestion.options, 4),
                    }
                });

                // Can't use Object.entries in firefox
                // const questions = Object.entries(categories)
                //     .reduce((acc, [type, { question, options }]) => {
                //         acc[type] = {
                //             question,
                //             options: chunk(options, 4),
                //         };
                //         return acc;
                //     }, {} as Questions);

                return questions;
            })
            .catch(this.api.handleError);
    }

    /**
     * Enables marketing for the client.
     *
     * @param {string} uuid
     * @param {string} signature
     *
     * @return {Promise<boolean>}
     */
    public enableMarketing (uuid: string, signature: string): Promise<boolean> {
        const url = this.signedRoute(`gdpr/${uuid}/enable-marketing`, signature);
        return this.api.post(url, {})
            .toPromise()
            .then(res => res['success'])
            .catch(this.api.handleError);
    }

    /**
     * Disables marketing for the client.
     *
     * @param {string} uuid
     * @param {string} signature
     *
     * @return {Promise<boolean>}
     */
    public disableMarketing (uuid: string, signature: string): Promise<boolean> {
        const url = this.signedRoute(`gdpr/${uuid}/disable-marketing`, signature);
        return this.api.post(url, {})
            .toPromise()
            .then(res => res['success'])
            .catch(this.api.handleError);
    }

    /**
     * Gets the clients preferences.
     *
     * @param {string} uuid
     * @param {string} signature
     *
     * @return {Promise<string[]>}
     */
    public getPreferences (uuid: string, signature: string): Promise<string[]> {
        const url = this.signedRoute(`gdpr/${uuid}/preferences`, signature);
        return this.api.get(url)
            .toPromise()
            .catch(this.api.handleError);
    }

    /**
     * Updates the clients preferences.
     *
     * @param {string} uuid
     * @param {string} signature
     * @param {string[]} selected
     */
    public updatePreferences (uuid: string, signature: string, selected: string[]): Promise<void> {
        const url = this.signedRoute(`gdpr/${uuid}/preferences`, signature);
        return this.api.post(url, { selected })
            .toPromise()
            .catch(this.api.handleError);
    }
}
