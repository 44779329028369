import { Injectable } from "@angular/core";
import { BaseDriver } from "./base-driver";
import { Contracts } from "../../../contracts/services/notifier/driver";
import { NotificationData } from "../../../contracts/services/notifier/notification-data";
import swal, { SweetAlertOptions, SweetAlertResult } from "sweetalert2";

@Injectable()
export class SweetAlertDriver extends BaseDriver implements Contracts.Driver {
    /**
     * Render a new notification.
     *
     * @param {string} status
     * @param {NotificationData} data
     */
    notify(status: string, data: NotificationData): Promise<any> {
        return swal
            .fire({
                title: data.title,
                text: data.body,
                html: data.html,
                type: status,
                confirmButtonClass: "btn btn-lg btn-primary",
                buttonsStyling: false
            } as SweetAlertOptions)
            .then(result => {
                // Only return the promise if the user selected OK
                if (result.value) {
                    return Promise.resolve(result);
                }

                // Reject the promise if the user selected cancel
                return Promise.reject(result);
            });
    }

    /**
     * Render a confirm message.
     *
     * @param data
     * @returns {any}
     */
    confirm(data: NotificationData): Promise<any> {
        return swal
            .fire({
                title: data.title,
                text: data.body,
                html: data.html,
                type: "warning",
                showCancelButton: true,
                confirmButtonClass: "btn btn-lg btn-primary",
                cancelButtonClass: "btn btn-lg btn-outline btn-default",
                buttonsStyling: false
            } as SweetAlertOptions)
            .then(result => {
                // Only return the promise if the user selected OK
                if (result.value) {
                    return Promise.resolve(result);
                }

                // Reject the promise if the user selected cancel
                return Promise.reject(result);
            });
    }
}
