import { Component, ElementRef, OnInit, Renderer2 } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { LocationStrategy } from "@angular/common";
import { pairwise } from "rxjs/operators";
import { LocalStorageService } from "../../services/storage/local-storage.service";
import { GroupService } from "../../services/groups/group.service";
import { DocumentRef, WindowRef } from "../../utilities/window-ref";
import * as psl from "psl";

declare const window: any;
declare var $: any;
declare var jQuery: any;

@Component({
    selector: "root",
    templateUrl: "./app.component.html"
})
export class AppComponent implements OnInit {
    /**
     * A flag indicating that the user navigated using popstate.
     *
     * @type {boolean}
     */
    protected isPopState = false;

    /**
     * The last known browser position for each url.
     *
     * @type {any[]}
     */
    protected routeScrollPositions: { [url: string]: number }[] = [];

    /**
     * The asset path for the current group.
     */
    public assetPath: string;

    constructor(
        protected router: Router,
        protected locationStrategy: LocationStrategy,
        protected localStorage: LocalStorageService,
        protected groupService: GroupService,
        protected documentRef: DocumentRef,
        protected windowRef: WindowRef,
        protected renderer: Renderer2,
        protected el: ElementRef
    ) {}

    ngOnInit(): void {
        this.loadAssetPath();

        // Check if the user has navigated via browser history
        this.locationStrategy.onPopState(() => {
            this.isPopState = true;
        });

        this.router.events
            .pipe(pairwise())
            .subscribe(([previousRoute, currentRoute]) => {
                if (previousRoute instanceof NavigationEnd) {
                    this.routeScrollPositions[previousRoute.url] =
                        window.pageYOffset;
                }

                if (currentRoute instanceof NavigationEnd) {
                    // If we are accessing page from browser history then navigate
                    // to the last stored location for the page
                    if (this.isPopState) {
                        window.scrollTo(
                            0,
                            this.routeScrollPositions[currentRoute.url] || 0
                        );
                    } else {
                        // Otherwise scroll to the top of the page
                        window.scrollTo(0, 0);
                    }

                    this.isPopState = false;
                }
            });
    }

    /**
     * Load the asset path for the current group.
     */
    protected loadAssetPath(): void {
        const domain = psl.get(
            this.windowRef.getNativeWindow().location.hostname
        );
        const key = this.assetPathKey(domain);

        if (this.localStorage.has(key)) {
            this.assetPath = this.localStorage.get<string>(key);

            this.setGroupClass(this.assetPath);
        } else {
            this.groupService.assetPath(domain).then(assetPath => {
                this.assetPath = assetPath;

                this.localStorage.set(key, assetPath);
                this.setGroupClass(this.assetPath);
            });
        }
    }

    /**
     * Add the asset path as a class to the body.
     */
    protected setGroupClass(assetPath: string): void {
        this.renderer.addClass(
            this.documentRef.getNativeDocument().body,
            assetPath
        );
    }

    /**
     * Get the asset path key.
     *
     * @param {string} domain
     * @returns {string}
     */
    protected assetPathKey(domain: string): string {
        return `${domain}.assetPath`;
    }
}
