import {Injectable} from '@angular/core';
import {ApiService} from '../api-service';
import {CustomNotification} from '../../contracts/requests/custom-notification.request';
import {NotificationResponse} from '../../contracts/responses/notification.response';

@Injectable()
export class NotificationService {

    constructor(protected apiService: ApiService) {
    }

    /**
     * Create a new notification.
     *
     * @param notification
     * @returns {Promise<boolean>}
     */
    public create(notification: CustomNotification): Promise<boolean> {
        return this.apiService.post('notifications', notification)
            .toPromise()
            .then((response: Object) => {
                return response['success'] as boolean;
            });
    }

    /**
     * Mark the provided notification as read.
     *
     * @param notification
     * @returns {Promise<boolean>}
     */
    public markAsRead(notification: any): Promise<boolean> {
        return this.apiService.post('notifications/mark-as-read', {notification: notification.id})
            .toPromise()
            .then((response: Object) => {
                return response['success'] as boolean;
            });
    }

    /**
     * Get notifications for the user
     *
     * @param {number} perPage
     * @param {number} from
     * @param {string} query
     * @return {Promise<NotificationResponse>}
     */
    public get(perPage: number, from: number = null, query: string = null): Promise<NotificationResponse> {
        return this.apiService.post(`notifications/search`, {
            per_page: perPage,
            from: from,
            query: query
        })
            .toPromise()
            .then((response: Object) => {
                return response as NotificationResponse;
            });
    }

    /**
     * Get notification history for the user.
     *
     * @param {number} perPage How many notifications to retrieve
     * @param {string} from The last identifier received
     * @return {Promise<NotificationResponse>}
     */
    public getHistory(perPage: number, from: number = null, query: string = null): Promise<NotificationResponse> {
        return this.apiService.post(`notifications/history`, {
            per_page: perPage,
            from: from,
            query: query
        })
            .toPromise()
            .then((response) => {
                return response as NotificationResponse;
            });
    }
}
