import {NgModule} from '@angular/core';
import {EchoService} from '../../services/echo/echo.service';
import {PrivateChannelFactory} from '../../services/echo/private-channel.factory';

@NgModule({
    providers: [
        EchoService,
        PrivateChannelFactory,
    ]
})
export class EchoModule {

}
