import {Injectable} from '@angular/core';

import {BaseDriver} from './drivers/base-driver';
import {Contracts} from '../../contracts/services/notifier/notifier';
import {NotificationData} from '../../contracts/services/notifier/notification-data';

@Injectable()

export class Notifier implements Contracts.Notifier {

    constructor(protected driver: BaseDriver) {}

    /**
     * Create a new notification of the provided status.
     *
     * @param {string} status
     * @param {Object} data
     */
    notify(status: string, data: NotificationData): Promise<any> {
        return this.driver.notify(status, data);
    }

    /**
     * Create an info level notification.
     *
     * @param {NotificationData} data
     */
    info(data: NotificationData): Promise<any> {
        return this.notify('info', data);
    }

    /**
     * Create a success level notification.
     *
     * @param {NotificationData} data
     */
    success(data: NotificationData): Promise<any> {
        return this.notify('success', data);
    }

    /**
     * Create a warning level notification.
     *
     * @param {NotificationData} data
     */
    warning(data: NotificationData): Promise<any> {
        return this.notify('warning', data);
    }

    /**
     * Create an error level notification.
     *
     * @param {NotificationData} data
     */
    error(data: NotificationData): Promise<any> {
        return this.notify('error', data);
    }

    /**
     * Create a confirm box.
     *
     * @param data
     * @returns {Promise}
     */
    confirm(data: NotificationData): Promise<any> {
        return this.driver.confirm(data);
    }

}
