import { Injectable } from '@angular/core';
import { ApiService } from '../api-service';
import { SharedData } from '../../contracts/shared-data';
import { JwtService } from '../jwt.service';

@Injectable()
export class SharedDataService {
    /**
     * @var {Promise<any>} data
     */
    // protected data: Promise<any>;
    protected data: Promise<SharedData>;

    constructor (protected apiService: ApiService, protected jwt: JwtService) {
        //
    }

    /**
     * Gets the data at the given key from the
     * shared data, using dot notation.
     *
     * @param {string} key
     * @param {T} [defaultIfNotExists=null]
     * @returns {Promise<T>}
     */
    public async get<T = Object>(key: string, defaultIfNotExists?: T): Promise<T>;

    /**
     * Gets the entire shared data object.
     *
     * @returns {Promise<SharedData>}
     */
    public async get(): Promise<SharedData>;

    /**
     * The actual implementation of get, not seen from outside this class.
     */
    public async get<T = Object>(key?: string, defaultIfNotExists: T = null): Promise<any> {
        const sharedData = await this.getSharedData();

        // If no key was provided, return sharedData
        if (!key) {
            return sharedData;
        }

        // Loop through each item in the dot-notated key
        const value: T = key.split('.').reduce((acc, part) => {
            // If the current item is an object and is not null,
            // return that objects value at the given key, or undefined.
            return typeof acc === 'object' && acc !== null
                ? acc[part]
                : undefined;
        }, sharedData);

        // Decide whether to return the value or the fallback value provided
        return value === undefined ? defaultIfNotExists : value;
    }

    /**
     * Get the shared data.
     */
    protected getSharedData(): Promise<SharedData> {
        if (!this.data) {
            this.data = this.apiService.get<SharedData>('shared-data')
                .toPromise()
                .catch(() => {
                    throw new Error('Failed to load shared data.');
                });
        }

        return this.data;
    }

    /**
     * Remove the cached shared data from the service.
     */
    public remove(): void {
        this.data = null;
    }
}
