import {Injectable} from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import {CookieService} from 'ngx-cookie';
import { Observable } from 'rxjs';

@Injectable()
export class JwtService {

    /**
     * The key used to store the JWT in the cookie.
     *
     * @type {string}
     */
    protected key = 'JWT_TOKEN';

    constructor(private cookieService: CookieService) {}

    /**
     * Get the current JWT.
     *
     * @returns {string}
     */
    public get(): string {
        return this.cookieService.get(this.key);
    }

    /**
     * Set the JWT.
     *
     * @param {string} token
     * @returns {boolean}
     */
    public set(token: string): Observable<any> {
        const date = new Date();

        date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 1000));

        this.cookieService.put(this.key, token, {
            path: '/',
            expires: date,
            secure: false,
        });

        return new Observable((observer: any) => {
            observer.next(token);
            observer.complete();
        });
    }

    /**
     * Forget the current JWT.
     */
    public forget(): void {
        this.cookieService.remove(this.key);
    }

    /**
     * Check that the current JWT token is valid.
     *
     * @returns {boolean}
     */
    public check(): boolean {
        const token = this.get();

        if (token) {
            const helper = new JwtHelperService();

            return ! helper.isTokenExpired(token);
        }

        return false;
    }

    public decode(): any {
        const token = this.get();

        if (token) {
            const helper = new JwtHelperService();

            return helper.decodeToken(token);
        }
    }
}
