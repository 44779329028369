import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth/auth.service";
import { AuthenticatableUser } from "../../models/authenticatable-user";
import { NotificationService } from "../../services/notifications/notification.service";
import { ApiService } from "../../services/api-service";
import { MFApiService } from "../../services/mfapi-service";
import { EchoService } from "../../services/echo/echo.service";
import { NotificationResponse } from "../../contracts/responses/notification.response";
import { Renderer } from "../../services/notifications/renderer";
import { NavigationEnd, Router } from "@angular/router";
import * as $ from "jquery";
import { DesktopNotificationService } from "../../services/desktop-notifications/desktop-notification.service";
import { debounceTime, filter } from "rxjs/operators";
import { FormControl } from "@angular/forms";
import { Notification } from "../../models/notification";
import { EchoComponent } from "../echo.component";

const toggleNotificationBar = function(e) {
    e.preventDefault();

    $("body").toggleClass("notification-bar-toggled");
};

const hideNotificationBar = function(e) {
    if ($(e.target).parents(".notification-bar").length == 0) {
        $("body").removeClass("notification-bar-toggled");
    }
};

@Component({
    selector: "notification-bar",
    templateUrl: "./templates/notification-bar.html"
})
export class NotificationBarComponent extends EchoComponent
    implements OnInit, AfterViewInit, OnDestroy {
    /**
     * The currently signed in user.
     *
     * @type {AuthenticatableUser}
     */
    public user: AuthenticatableUser;

    /**
     * The notifications we are displaying.
     *
     * @type {any[]}
     */
    public notifications: Notification[] = [];

    /**
     * The total number of notifications.
     *
     * @type {number}
     */
    public total = 0;

    /**
     * How many notifications to load per page.
     *
     * @type {number}
     */
    protected PER_PAGE = 30;

    /**
     * A flag indicating that we have loaded all of the notifications.
     *
     * @type {boolean}
     */
    public doneLoading = false;

    /**
     * Array of requests that have been fired already,
     * so we can check we're not firing the same request twice.
     *
     * @type {number[]}
     */
    protected fired: number[] = [];

    /**
     * @type {string}
     */
    protected currentUrl = "";

    /**
     * The terms to search against.
     *
     * @type {FormControl}
     */
    public terms = new FormControl();

    /**
     * The terms to search against.
     */
    public query: string;

    /**
     * A flag indicating that the preloader should be displayed.
     *
     * @type {boolean}
     */
    public showPreLoader = true;

    constructor(
        protected authService: AuthService,
        protected notificationService: NotificationService,
        protected echo: EchoService,
        protected apiService: ApiService,
        protected MFApiService: MFApiService,
        protected renderer: Renderer,
        protected desktopNotifications: DesktopNotificationService,
        protected router: Router
    ) {
        super(echo);
    }

    ngOnInit(): void {
        this.authService.user().then(user => {
            this.user = user;

            this.terms.valueChanges.pipe(debounceTime(400)).subscribe(value => {
                this.query = value;
                this.notifications = [];
                this.fired = [];
                this.doneLoading = false;
                this.showPreLoader = true;

                this.search();
            });

            this.search();

            // Occasionally the notifications don't auto update through the socket.
            // Here we subscribe to the router event, and when the first segment
            // of the url changes we reload the users notifications.
            this.router.events
                .pipe(filter(event => event instanceof NavigationEnd))
                .subscribe((event: NavigationEnd) => {
                    const parts = event.urlAfterRedirects.split("/");
                    const segment = parts[1] || "";

                    if (segment !== this.currentUrl) {
                        this.currentUrl = segment;

                        this.search();
                    }
                });

            this.registerListeners();
        });
    }

    ngAfterViewInit(): void {
        $(document).on(
            "click",
            ".toggle-notification-bar",
            toggleNotificationBar
        );
        $(document).on(
            "click",
            ".notification-bar-toggled .wrapper",
            hideNotificationBar
        );
    }

    ngOnDestroy(): void {
        $(document).off(
            "click",
            ".toggle-notification-bar",
            toggleNotificationBar
        );
        $(document).off(
            "click",
            ".notification-bar-toggled .wrapper",
            hideNotificationBar
        );

        super.ngOnDestroy();
    }

    /**
     * Check if we have loaded all of the notifications, if not then search.
     */
    public onScroll(): void {
        if (this.doneLoading) {
            return;
        }

        this.search();
    }

    /**
     * Search for notifications.
     */
    protected search(): void {
        let from = 0;

        if (this.notifications && this.notifications.length) {
            from = this.notifications.length;
        }

        // Check we havent already fired this request
        if (!this.fired.includes(from)) {
            // We're firing it now, add it to the fired array.
            // If the request fails, we'll remove it again in the catch().
            this.fired.push(from);

            this.notificationService
                .get(this.PER_PAGE, from, this.query)
                .then((data: NotificationResponse) => {
                    this.notifications.push(...data.notifications);
                    this.total = data.total;
                    this.showPreLoader = false;

                    this.checkIfDone(data.total);
                })
                .catch(err => console.dir(err));
        }
    }

    /**
     * Check if we have done loading notifications.
     *
     * @param {number} total - The total number of notifications expected.
     * @return {boolean} - true if done, else false
     */
    protected checkIfDone(total: number): boolean {
        const done = this.notifications.length >= total;

        this.doneLoading = done;

        return done;
    }

    /**
     * Mark the provided notification as read.
     *
     * @param notification
     */
    public markAsRead(notification: any): void {
        this.notificationService
            .markAsRead(notification)
            .then(() => {
                this.removeNotification(notification);
            })
            .catch(error => {
                switch (error.status) {
                    case 403:
                        if (notification.url) {
                            this.redirectAndHide(notification.url);
                        }

                        break;
                    case 404:
                        this.removeNotification(notification);

                        break;
                    default:
                        this.apiService.handleError(error).catch(() => {});
                }
            });
    }

    /**
     * Remove the notification from the sidebar. If the notification has a
     * URL redirect to it.
     */
    protected removeNotification(notification: Notification): void {
        const notifications = this.notifications.filter(
            ({ id }) => id !== notification.id
        ) as Notification[];

        if (notifications.length !== this.notifications.length) {
            this.notifications = notifications;
            this.total--;
        }

        if (notification.url) {
            this.redirectAndHide(notification.url);
        }
    }

    /**
     * Redirect to the url and hide the notification bar.
     *
     * @param {string} url
     */
    protected redirectAndHide(url: string): void {
        this.router.navigateByUrl(url);

        this.hide();
    }

    /**
     * Hide the notification bar.
     */
    public hide(): void {
        $("body").removeClass("notification-bar-toggled");
    }

    /**
     * Parse the notifications body,
     *
     * @param {string} body
     * @return {string}
     */
    public parse(body: string): string {
        return this.renderer.parse(body);
    }

    /**
     * Register the echo event listeners.
     */
    protected registerListeners(): void {
        try {
            this.joinChannel(`users.${this.user.identifier}.notifications`)
                .notification((event: any) => {
                    this.notifications.unshift(event);
                    this.total++;

                    this.desktopNotifications.notify({
                        title: event.title || "Notification",
                        body: event.body ? this.parse(event.body) : "",
                        onClick: () => {
                            window.focus();

                            if (event.url) {
                                this.markAsRead(event);
                            } else {
                                document.body.className +=
                                    " notification-bar-toggled";
                            }
                        }
                    });
                })
                .event("notificationRead", ({ notification }: any) => {
                    this.total--;

                    if (notification) {
                        this.notifications = this.notifications.filter(
                            ({ id }) => id !== notification.id
                        ) as Notification[];
                    }
                });
        } catch (e) {
            // Handle echo service not running.
        }
    }
}
