import {Injectable, NgZone} from '@angular/core';
import {PrivateChannel} from './private-channel';

@Injectable()
export class PrivateChannelFactory {
    constructor(private zone: NgZone) {}

    /**
     * Create a new private channel.
     *
     * @param channel
     * @returns {PrivateChannel}
     */
    public make(channel: any): PrivateChannel {
        return new PrivateChannel(this.zone, channel);
    }
}
