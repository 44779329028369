import { PipeTransform, Pipe, Injectable } from '@angular/core';
import { HasIdentifier } from '../contracts/has-identifier';

@Pipe({
    name: 'ignore',
    pure: false
})
@Injectable()
export class IgnorePipe implements PipeTransform {
    /**
     * Remove the value from the array where
     *
     * @param values
     * @param args
     * @returns {HasIdentifier[]}
     */
    transform(values: HasIdentifier[], ...args: string[]): any {
        if (args.length > 0) {
            const identifier = args[0];

            return values.filter((value) => {
                return value.identifier != identifier;
            });
        }

        return values;
    }
}
