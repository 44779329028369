// Lib
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Services
import { AuthService } from '../auth.service';

// Helperss
import { catcher } from '../../../helpers/other';

@Injectable()
export class AuthGuardService  {
    constructor (private authService: AuthService, private router: Router) {
        //
    }

    /**
     * Check that a user is signed in.
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Promise<boolean>}
     */
    public canActivate (
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Promise<boolean> {
        return this.check();
    }

    /**
     * Check that a user is signed in before activating the child route.
     *
     * @param {ActivatedRouteSnapshot} childRoute
     * @param {RouterStateSnapshot} state
     * @returns {Promise<boolean>}
     */
    public canActivateChild (
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Promise<boolean> {
        return this.check();
    }

    /**
     * Check if a user is signed in before loading the child group.
     *
     * @param {Route} route
     * @returns {Promise<boolean>}
     */
    public canLoad (route: Route): Promise<boolean> {
        return this.check();
    }

    /**
     * Check if a user is signed in.
     *
     * @returns {Promise<boolean>}
     */
    protected async check (): Promise<boolean> {
        const check = await this.authService.check();

        if (!check) {
            const { err } = await catcher(this.authService.newTokenAsPromise());

            if (err) {
                this.router.navigate(['/login']);
                return false;
            }
        }

        return true;
    }
}
