import {Injectable} from '@angular/core';
import {NotificationData} from '../../../contracts/services/notifier/notification-data';

@Injectable()

export abstract class BaseDriver {
    /**
     * Render a new notification.
     *
     * @param {string} status
     * @param {NotificationData} data
     */
    abstract notify(status: string, data: NotificationData): Promise<any>;

    /**
     * Render a confirm message.
     *
     * @param data
     * @returns {any}
     */
    abstract confirm(data: NotificationData): Promise<any>;
}
