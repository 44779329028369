<div class="wrapper">
    <sidebar></sidebar>

    <main class="content-wrapper">
        <nav class="navbar navbar-default">
            <div class="container-fluid">
                <ul class="nav navbar-top-links navbar-left">
                    <li class="nav-item">
                        <a href="#" class="toggle-sidebar">
                            <div class="icon-bar-wrapper">
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                            </div>
                        </a>
                    </li>
                    <li
                        class="nav-item dropdown"
                        *ngIf="
                            selectedGroup &&
                            currentUser.can('groups.access.all')
                        "
                    >
                        <a
                            href="#"
                            class="nav-link dropdown-toggle"
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            {{ selectedGroup.name }} <span class="caret"></span>
                        </a>
                        <ul class="dropdown-menu">
                            <li
                                class="dropdown-item"
                                *ngFor="let group of groups"
                            >
                                <a
                                    class="dropdown-item cursor-pointer"
                                    (click)="switchGroup(group)"
                                >
                                    {{ group.name }}
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </nav>

        <!--@if (config('app.env') != 'production')-->
        <!--<div class="environment-banner">-->
        <!--This is the {{ config('app.env') }} version of this application-->
        <!--</div>-->
        <!--@endif-->

        <banner-bar></banner-bar>

        <router-outlet></router-outlet>
    </main>

    <aside class="notification-bar">
        <notification-bar></notification-bar>
    </aside>
</div>

<timezone-selector></timezone-selector>
<app-version></app-version>
<login-modal></login-modal>
