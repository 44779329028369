import {User} from './user';
import {Permissions} from '../contracts/models/permissions';
import {Group} from './group';
import {Preferences} from '../contracts/models/preference';
import { Permission } from '../contracts/models/permission';
import { ContactDetail } from './user/contact-detail';

export class AuthenticatableUser {

    /**
     * The user's UUID.
     *
     * @type {string}
     */
    identifier: string;

    /**
     * The user's group identifier.
     *
     * @type {string}
     */
    group_id?: string;

    /**
     * The user's first name.
     *
     * @type {string}
     */
    first_name: string;

    /**
     * The user's surname.
     *
     * @type {string}
     */
    last_name: string;

    /**
     * The user's full name.
     *
     * @type {string}
     */
    full_name: string;

    /**
     * The user's email.
     *
     * @type {string}
     */
    email: string;

    /**
     * The user's landline.
     *
     * @type {string}
     */
    landline: string;

    /**
     * The user's mobile.
     *
     * @type {string}
     */
    mobile: string;

    /**
     * A flag indicating if the user is active or not.
     *
     * @type {string}
     */
    active?: boolean;

    /**
     * The user's permissions.
     *
     * @type {Permission}
     */
    permissions?: Permissions;

    /**
     * The user's group.
     */
    group?: Group;

    /**
     * All of the notifications for the user.
     */
    notifications: Array<any>;

    /**
     * All of the preferences for the user.
     */
    preferences: Preferences;

    contactDetails: ContactDetail[];

    constructor(data: User, permissions: Permissions) {
        this.identifier = data.identifier;
        this.group_id = data.group_id;
        this.first_name = data.first_name;
        this.last_name = data.last_name;
        this.full_name = data.full_name;
        this.landline = data.landline;
        this.mobile = data.mobile;
        this.email = data.email;
        this.active = data.active;
        this.group = data.group;
        this.notifications = data.notifications;
        this.preferences = data.preferences;
        this.contactDetails = data.contactDetails;
        this.permissions = permissions;
    }

    /**
     * Check if the user has access to the provided permission.
     *
     * @param {string} permission
     * @returns {boolean}
     */
    public can(permission: Permission): boolean {
        if (permission) {
            return permission in this.permissions;
        }

        return false;
    }

    /**
     * Check if a user cannot access the provided permission.
     *
     * @param {string} permission
     * @returns {boolean}
     */
    public cannot(permission: Permission): boolean {
        return ! this.can(permission);
    }

    /**
     * Get the users js-safe date/datetime, converted to moment format if needed.
     *
     * @param {boolean} moment
     * @returns {any}
     */
    public getDateFormat(moment: boolean = false, time: boolean = false): string {
        let format = '';

        if (this.preferences.date_format == 'm/d/Y') {
            format = moment ? 'MM/DD/YYYY' : 'mm/dd/yyyy';
        } else if (this.preferences.date_format == 'd/m/Y' || !this.preferences.date_format || !this.preferences) {
            format = moment ? 'DD/MM/YYYY' : 'dd/mm/yyyy';
        } else {
            format = this.preferences.date_format;
        }

        if (time) {
            format += moment ? ' HH:mm' : ' hh:ii';
        }

        return format;
    }
}
