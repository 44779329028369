import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { SharedDataService } from '../services/shared-data/shared-data.service';
import { Group } from '../models/group';
import { Notifier } from '../services/notifier/notifier';

@Injectable()
export class CanAccessModuleGuard  {
    protected group: Group;

    constructor(protected sharedDataService: SharedDataService, protected router: Router, protected notifier: Notifier) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        return this.canAccessModule(route.data['module']);
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        return this.canAccessModule(childRoute.data['module']);
    }

    canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
        return this.canAccessModule(route.data['module']);
    }

    /**
     * Check that the current group has access to the module.
     */
    protected canAccessModule(module: string): Promise<boolean> {
        return this.getGroup().then((group) => {
            const canAccess = group.has(module);

            if ( ! canAccess) {
                this.router.navigateByUrl('/');
                this.notifier.error({
                    title: 'Access Denied',
                    body: 'You do not have permissions to access that'
                });

                console.log(`ACCESS DENIED: '${module}'`);
            }

            return canAccess;
        });
    }

    /**
     * Get all of the modules for the group.
     */
    protected getGroup(): Promise<Group> {
        if (this.group) {
            return Promise.resolve(this.group);
        }

        return this.sharedDataService.get<Group>('group')
                   .then((group) => {
                       return this.group = new Group(group);
                   });
    }
}
