import {Injectable} from '@angular/core';
import {AppVersion} from '../../contracts/app-version';
import { ApiService } from '../api-service';

@Injectable()
export class VersionService {
    constructor(private api: ApiService) {}

    /**
     * Get the latest version of the application.
     *
     * @returns {Promise<any>}
     */
    public latest(): Promise<AppVersion> {
        return this.api.get<AppVersion>('version')
                   .toPromise()
                   .catch(this.api.handleError);
    }
}
