import { NgModule } from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {UserService} from '../../services/users/user.service';
import {AuthService} from '../../services/auth/auth.service';
import {JwtService} from '../../services/jwt.service';
import {AuthEventBus} from '../../events/auth/auth.event.bus';
import {AuthGuardService} from '../../services/auth/guards/auth-guard.service';
import {CanAccessGuardService} from '../../services/auth/guards/can-access-guard.service';
import {RouterModule} from '@angular/router';
import {CookieModule} from 'ngx-cookie';
import { CanAccessModuleGuard } from '../../guards/can-access-module.guard';

@NgModule({
    imports: [ HttpClientModule, RouterModule, CookieModule.forRoot() ],
    providers: [ UserService, AuthService, JwtService, AuthEventBus, AuthGuardService, CanAccessGuardService, CanAccessModuleGuard ],
    exports: [ CookieModule ]
})
export class AuthModule {

}
