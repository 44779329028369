<auth>
    <div auth-sidebar>
        <!--<div class="text-center">-->
        <!--<img src="assets/img/kestrel-portal-logo.png" class="img-responsive" style="margin-bottom: 50px; max-width: 200px; display: inline-block;">-->
        <!--</div>-->

        <form
            novalidate
            role="form"
            (ngSubmit)="login(loginForm)"
            #loginForm="ngForm"
        >
            <div class="alert alert-danger" *ngIf="error">
                <p>There was an issue with the login credentials.</p>
            </div>

            <div
                class="form-group"
                [ngClass]="{'has-error': ! emailControl.valid && (emailControl.touched || loginForm.submitted)}"
            >
                <div class="input-group">
                    <span class="input-group-prepend">
                        <i class="input-group-text icon-envelope"></i>
                    </span>
                    <input
                        type="email"
                        name="email"
                        class="form-control"
                        placeholder="Email"
                        [(ngModel)]="email"
                        required
                        #emailControl="ngModel"
                    />
                </div>
                <div
                    class="form-errors"
                    *ngIf=" ! emailControl.valid && (emailControl.touched || loginForm.submitted)"
                >
                    <p class="help-block">The email field is required.</p>
                </div>
            </div>

            <div
                class="form-group"
                [ngClass]="{'has-error': ! passwordControl.valid && (passwordControl.touched || loginForm.submitted)}"
            >
                <div class="input-group">
                    <span class="input-group-prepend">
                        <i class="input-group-text icon-locked"></i>
                    </span>
                    <input
                        type="password"
                        name="password"
                        class="form-control"
                        placeholder="Password"
                        [(ngModel)]="password"
                        required
                        #passwordControl="ngModel"
                    />
                </div>
                <div
                    class="form-errors"
                    *ngIf=" ! passwordControl.valid && (passwordControl.touched || loginForm.submitted)"
                >
                    <p class="help-block">The email field is required.</p>
                </div>
            </div>

            <div class="form-group">
                <label>
                    <input type="checkbox" name="remember" value="1" /> Remember
                    me
                </label>
            </div>

            <div class="form-group">
                <button
                    type="submit"
                    class="btn btn-primary btn-block"
                    [animateLoading]="submitting"
                >
                    Sign In
                </button>
            </div>
        </form>

        <div class="text-center gutter-top">
            <a href="#">Forgotten you password? Click here</a>
        </div>
    </div>
</auth>
