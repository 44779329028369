import { Injectable, EventEmitter } from '@angular/core';
import { SessionStorageService } from '../../services/storage/session-storage.service';
import {JwtService} from '../../services/jwt.service';

@Injectable()
export class AuthEventBus {
    /**
     * The login event emitter.
     *
     * @var {EventEmitter<any>}
     */
    public login: EventEmitter<any> = new EventEmitter<any>();

    /**
     * The logout event emitter.
     *
     * @var {EventEmitter<any>}
     */
    public logout: EventEmitter<any> = new EventEmitter<any>();

    /**
     * The impersonate event emitter.
     *
     * @var {EventEmitter<any>}
     */
    public impersonate: EventEmitter<any> = new EventEmitter<any>();

    /**
     * The token invalid event emitter.
     *
     * @type {EventEmitter<any>}
     */
    public tokenInvalid: EventEmitter<any> = new EventEmitter<any>();
}
