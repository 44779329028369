/**
 * Curried function that takes an object, and returns a
 * function that takes a property name, which returns true
 * if the property exists on the object, otherwise false.
 *
 * @param {object} o - The object
 * @return {(p: string) => boolean}
 */
export const has = (o: object) => (p: string): boolean =>
    Object.prototype.hasOwnProperty.call(o, p);

/**
 * Curried function that takes a property name, and returns
 * a function that takes an object, which returns true if the
 * property exists on the object, otherwise false.
 *
 * @param {string} p - The property
 * @return {(o: object) => boolean}
 */
export const keyExists = (p: string) => (o: object): boolean =>
    Object.prototype.hasOwnProperty.call(o, p);

/**
 * Create a copy of the provided entity and remove any references.
 *
 * @param original
 * @returns {any}
 */
export function copy(original: any) {
    return JSON.parse(JSON.stringify(original));
}

export function values(object: any): Array<any> {

    const objectHas = has(object);
    const array = [];
    
    for (const key in object) {
        if (objectHas(key)) {
            array.push(object[key]);
        }
    }

    return array;
}
