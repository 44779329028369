// Array.prototype.filter helper methods
export const filters = {
    /**
     * Filters to only unique elements.
     *
     * @param {any} v - The current array value
     * @param {number} i - The current index
     * @param {array} a - The array being filtered
     *
     * @return {boolean}
     */
    unique: (v, i, a) => a.indexOf(v) === i,

    /**
     * Filters to only truthy elements.
     *
     * @param {any} v - The current array value
     *
     * @return {boolean}
     */
    truthy: v => !!v,
};

/**
 * Chunks an array into chunks of the given size.
 *
 * @param {T[]} a - The array to chunk
 * @param {number} s - The size of the chunks
 * @returns {T[][]} - The chunked array
 */
export const chunk = <T> (a: T[], s: number): T[][] => {
    const arr = [...a];
    const chunked = [];

    while (arr.length >= s) {
        chunked.push(arr.splice(0, s));
    }

    if (arr.length) {
        chunked.push(arr);
    }

    return chunked;
};

/**
 * Flattens an array. Only works 1 level deep.
 *
 * @param {T[][]} a
 * @returns {T[]}
 */
export const flatten = <T> (a: T[][]): T[] => {
    return [].concat(...a);
};

/**
 * Gets the last item in the given array.
 *
 * @param {T[]} a
 * @returns {T}
 */
export const last = <T> (a: T[]) => {
    if (! a.length) {
        return;
    }

    return a[a.length - 1];
};

/**
 * Divides an array into 2 based on the given predicate function.
 * Return true to add the item to array 1, or false to add to array 2.
 *
 * @param {T[]} arr
 * @param {(T) => boolean} predicate
 *
 * @return {[T[], T[]]}
 */
export const divide = <T> (arr: T[], predicate: (T) => boolean): [T[], T[]] => {
    return arr.reduce<[T[], T[]]>((acc, item) => {
        acc[predicate(item) ? 0 : 1].push(item);
        return acc;
    }, [[], []]);
};
