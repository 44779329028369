// Lib
import { Injectable } from '@angular/core';

// Services
import { ApiService } from '../api-service';
import { JwtService } from '../jwt.service';
import { SessionStorageService } from '../storage/session-storage.service';

// Events
import { AppVersionEventBus } from '../../events/system/app-version.event.bus';
import { AuthEventBus } from '../../events/auth/auth.event.bus';

// Contracts
import { Item } from '../../contracts/sidebar/item';
import { Sidebar } from '../../contracts/sidebar/sidebar';

// Helpers & Config
import { catcher } from '../../helpers/other';
import { SESSION_STORAGE_KEYS as SK } from '../../constants';

@Injectable()
export class SidebarService {
    /**
     * The sidebar API request result.
     *
     * @var {Promise<Sidebar | false>} data
     */
    protected data: Promise<Sidebar | false>;

    /**
     * Initiates the service.
     *
     * @param {ApiService} apiService
     * @param {SessionStorageService} storage
     */
    constructor (
        private apiService: ApiService,
        private storage: SessionStorageService,
        private jwt: JwtService,
    ) {
        //
    }

    /**
     * Gets the sidebar items
     *
     * @return {Promise<any[]>}
     */
    public async getItems (): Promise<Item[]> {
        const data = await this.getSidebarItems();

        if (data) {
            return data.items;
        }
    }

    /**
     * Gets the logo
     *
     * @return {Promise<string>}
     */
    public async getLogo (): Promise<string> {
        const data = await this.getSidebarItems();

        if (data) {
            return data.logo;
        }
    }

    /**
     * Get the first url from the sidebar items.
     *
     * @returns {Promise<string>}
     */
    public async getRootUrl(): Promise<string> {
        const items = await this.getItems();

        if (!this.jwt.get()) {
            return '/login';
        }

        for (const item of items) {
            if (item.url) {
                return item.url;
            }

            if (item.items.length > 0) {
                return item.items[0].url;
            }
        }
    }

    /**
     * Remove the sidebar items from session storage, and reset
     * the data in the service.
     *
     * @returns {this}
     */
    public remove(): SidebarService {
        this.storage.remove(SK.SIDEBAR);
        this.data = null;

        return this;
    }

    /**
     * Get the sidebar items.
     *
     * @returns {Promise<Sidebar | false>}
     */
    protected async getSidebarItems(): Promise<Sidebar | false> {
        if (!this.data) {
            if (this.storage.has(SK.SIDEBAR)) {
                this.data = Promise.resolve(this.storage.get<Sidebar>(SK.SIDEBAR));
            } else {
                const sidebar = await this.load();

                if (sidebar) {
                    this.storage.set(SK.SIDEBAR, sidebar);
                    this.data = Promise.resolve(sidebar);
                } else {
                    this.data = Promise.resolve(false) as Promise<false>;
                }
            }
        }

        return this.data;
    }

    /**
     * Loads the data from the server.
     *
     * @returns {Promise<Sidebar | false>}
     */
    protected load (): Promise<Sidebar | false> {
        if (!this.jwt.get()) {
            return Promise.resolve(false) as Promise<false>;
        }

        return this.apiService.get('sidebar')
            .toPromise()
            .then(res => <Sidebar>res)
            .catch((err): false => {
                console.log('An error occurred fetching data', err);
                return false;
            });
    }
}
