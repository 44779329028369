import { EchoService } from "../services/echo/echo.service";
import { PrivateChannel } from "../services/echo/private-channel";
import { OnDestroy } from "@angular/core";
import { HasSubscriptions } from "./has-subscriptions";
import { Directive } from "@angular/core";

@Directive()
export class EchoComponent extends HasSubscriptions implements OnDestroy {
    /**
     * All of the channels we have subscribed to.
     */
    protected channels: string[] = [];

    constructor(protected echo: EchoService) {
        super();
    }

    ngOnDestroy(): void {
        this.leaveChannels();
    }

    /**
     * Subscribe to the provided channel.
     */
    protected joinChannel(channel: string): PrivateChannel {
        this.channels.push(channel);

        return this.echo.private(channel);
    }

    /**
     * Unsubscribe from the provided channel.
     */
    protected leaveChannel(channel: string): void {
        try {
            this.echo.leave(channel);
        } catch (error) {
            // Handle echo service not running
        }

        this.channels = this.channels.filter(c => c != channel);
    }

    /**
     * Unsubscribe from each of the echo channels.
     */
    protected leaveChannels(): void {
        this.channels.forEach(channel => {
            this.leaveChannel(channel);
        });
    }
}
