import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthService} from '../auth.service';
import {Notifier} from '../../notifier/notifier';
import { SidebarService } from '../../sidebar/sidebar.service';
import { Permission } from '../../../contracts/models/permission';
import { JwtService } from '../../jwt.service';

@Injectable()
export class CanAccessGuardService  {
    /**
     * The URL the user is trying to access.
     */
    protected url: string;

    constructor(
        private authService: AuthService,
        private sidebarService: SidebarService,
        private router: Router,
        private notifier: Notifier,
        private jwt: JwtService,
    ) {}

    /**
     * Check that the user has sufficient permissions to activate the route.
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Promise<boolean>}
     */
    public canActivate (
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Promise<boolean> {
        this.url = state.url;
        return this.can(route.data['permission']);
    }

    /**
     * Check that the user has sufficient permissions to activate the
     * child route.
     *
     * @param {ActivatedRouteSnapshot} childRoute
     * @param {RouterStateSnapshot} state
     * @returns {Promise<boolean>}
     */
    public canActivateChild (
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Promise<boolean> {
        return this.can(childRoute.data['permission']);
    }

    /**
     * Check that the user has sufficient permissions to load the route
     * group.
     *
     * @param {Route} route
     * @returns {Promise<boolean>}
     */
    public canLoad (route: Route): Promise<boolean> {
        return this.can(route.data['permission']);
    }

    /**
     * Check if the user has access to the permission.
     *
     * @param {ActivatedRouteSnapshot} route
     * @returns {Promise<boolean>}
     */
    protected can(permission: Permission): Promise<boolean> {
        return this.authService.user()
            .then((user) => {
                if (user.can(permission)) {
                    return true;
                }

                return this.handleInsufficientPermissions();
            })
            .catch(() => this.handleInsufficientPermissions());
    }

    /**
     * Handle the current user having insufficient permission for the
     * current route.
     *
     * @returns {boolean}
     */
    protected handleInsufficientPermissions(): boolean {
        this.sidebarService.getRootUrl()
            .then((url) => {
                if (this.jwt.get()) {
                    this.router.navigateByUrl(url);
                    this.notifier.warning({
                        title: 'Insufficient Permissions',
                        body: 'You do not have permission to access that section.'
                    });
                } else {
                    this.router.navigateByUrl(`/login?redirect=${encodeURIComponent(this.url)}`);
                }
            });

        return false;
    }

}
