import { Injectable } from '@angular/core';
import { ApiService } from '../api-service';
import { Contracts } from '../../contracts/services/group';
import { Group } from '../../models/group';
import { Paginator } from '../../core/paginator/paginator';
import { PaginationData } from '../../core/paginator/pagination-data';
import { Company } from '../../models/company';
import { URLWithPath } from '../../types/URLBuilder/URLWithPath';
import { DeleteResponse } from '../../contracts/responses/delete.response';
import { RestoreResponse } from '../../contracts/responses/restore.response';
import { copy } from '../../helpers/objects';

@Injectable()

export class GroupService implements Contracts.Group {

    constructor(protected apiService: ApiService) {
    }

    /**
     * Get all of the groups.
     *
     * @return { Promise<Group[]> }
     */
    public all(relations: string[] = []): Promise<Group[]> {
        const url: URLWithPath = {
            path: 'groups',
            query: {},
        };

        if (relations.length) {
            url.query.with = relations.join(',');
        }

        return this.apiService.get<Group[]>(this.apiService.buildUrl(url))
                   .toPromise()
                   .catch(this.apiService.handleError);
    }

    /**
     * Get a paginated list of groups.
     *
     * @param {number} perPage
     * @param {number} page
     * @return { Promise<Paginator> }
     */
    public paginate(per_page: number, page: number): Promise<Paginator<Group>> {
        const url = this.apiService.buildUrl({
            path: 'groups',
            query: {per_page, page},
        });
        return this.apiService.get<PaginationData<Group>>(url)
                   .toPromise()
                   .then(res => new Paginator(res))
                   .catch(this.apiService.handleError);
    }

    /**
     * Get a paginated list of archived groups.
     *
     * @param {number} perPage
     * @param {number} page
     * @return { Promise<Paginator> }
     */
    public paginateArchived(per_page: number, page: number): Promise<Paginator<Group>> {
        const url = this.apiService.buildUrl({
            path: 'groups/archived',
            query: {per_page, page},
        });
        return this.apiService.get<PaginationData<Group>>(url)
                   .toPromise()
                   .then(res => new Paginator(res))
                   .catch(this.apiService.handleError);
    }

    /**
     * Find a group by their identifier.
     *
     * @param {string} identifier
     * @return { Promise<Group> }
     */
    public find(identifier: string, relations: string[] = []): Promise<Group> {
        const url: URLWithPath = {
            path: `groups/${identifier}`,
            query: {},
        };

        if (relations.length) {
            url.query.with = relations.join(',');
        }

        return this.apiService.get<Group>(this.apiService.buildUrl(url))
                   .toPromise()
                   .catch(this.apiService.handleError);
    }

    /**
     * Find a group by their domain.
     *
     * @param {string} domain
     * @returns {Promise<Group>}
     */
    public assetPath(domain: string): Promise<string> {
        return this.apiService.post<{asset_path: string}>('groups/asset-path', {domain: domain})
                   .toPromise()
                   .then((response) => response.asset_path)
                   .catch(this.apiService.handleError);
    }

    /**
     * Create a new group and then return the new group model.
     *
     * @param {Object} group
     * @returns {Promise<Group>}
     */
    public create(group: Group): Promise<Group> {
        return this.apiService.post<Group>('groups', this.getData(group))
            .toPromise()
            .catch(this.apiService.handleError);
    }

    /**
     * Update a group, and then return the updated group model.
     *
     * @param {Group} group
     * @returns {Observable<Group>}
     */
    public update(group: Group): Promise<Group> {
        return this.apiService.put<Group>(`groups/${group.identifier}/update`, this.getData(group))
            .toPromise()
            .catch(this.apiService.handleError);
    }

    private getData(group: Group): any {
        const modules = group.modules.map(module => module.name);
        const agents = copy(group.agents).map((agent) => {
            agent.tariffs = agent.tariffs.map(tariff => tariff.identifier);
            agent.companies = agent.companies.map(company => company.identifier);

            return agent;
        });

        return {
            identifier: group.identifier,
            name: group.name,
            domain: group.domain,
            phone: group.phone,
            fax: group.fax,
            email: group.email,
            website: group.website,
            company_number: group.company_number,
            vat_number: group.vat_number,
            logo: group.logo,
            modules,
            agents,
        };
    }

    /**
     * Delete a group.
     *
     * @param {Group} group
     * @returns {Promise<boolean>}
     */
    public delete(group: Group): Promise<boolean> {
        return this.apiService.delete<DeleteResponse>(`groups/${group.identifier}/destroy`)
                   .toPromise()
                   .then(res => res.deleted)
                   .catch(this.apiService.handleError);
    }

    /**
     * Restore a group.
     *
     * @param {Group} group
     * @returns {Promise<boolean>}
     */
    public restore(group: Group): Promise<boolean> {
        return this.apiService.put<RestoreResponse>(`groups/${group.identifier}/restore`, {})
                   .toPromise()
                   .then(res => res.restored)
                   .catch(this.apiService.handleError);
    }

    /**
     * Get all of the companies within the provided group.
     *
     * @param group
     * @returns {Promise<Company[]>}
     */
    public companies(group: string): Promise<Company[]> {
        return this.apiService.get<Company[]>(`groups/${group}/companies`)
                   .toPromise()
                   .catch(this.apiService.handleError);
    }
}
