import {Injectable} from '@angular/core';
import {PrivateChannelFactory} from './private-channel.factory';
import {PrivateChannel} from './private-channel';
import {JwtService} from '../jwt.service';

import {environment} from '../../../environments/environment';
import Echo from 'laravel-echo';

@Injectable()
export class EchoService {
    /**
     * The laravel echo instance.
     */
    private echo: Echo;

    constructor(private privateChannelFactory: PrivateChannelFactory, private jwtService: JwtService) {
        try {
            this.echo = new Echo({
                broadcaster: 'socket.io',
                host: environment['socket_url'] + ':' + environment['io_socket'],
                namespace: '',
                auth: {
                    headers: {
                        'Authorization': 'Bearer ' + this.jwtService.get()
                    }
                },
                transports: ['websocket']
            });
        } catch (e) {
            console.error('echo not running', e);
        }
    }

    /**
     * Subscribe to a private channel.
     *
     * @param channel
     * @returns {PrivateChannel}
     */
    public private(channel: string): PrivateChannel {
        try {
            const privateChannel = this.echo.private(channel);

            return this.privateChannelFactory.make(privateChannel);
        } catch (e) {
            throw new Error('Echo service is not running.');
        }
    }

    /**
     * Leave the channel.
     */
    public leave(channel: string): void {
        this.echo.leave(channel);
    }
}
