import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GdprComponent } from '../../components/gdpr/gdpr.component';
import { GdprPreferencesComponent } from '../../components/gdpr/gdpr-preferences.component';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: ':uuid/:signature',
                component: GdprComponent,
            },
            {
                path: ':uuid/preferences/:signature',
                component: GdprPreferencesComponent,
            },
        ]),
    ],
    exports: [
        RouterModule,
    ],
})
export class GdprRoutingModule {
    //
}
