import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth/auth.service';
import {Router, ActivatedRoute} from '@angular/router';
import {NgForm} from '@angular/forms';
import {AuthEventBus} from '../../events/auth/auth.event.bus';
import { UserService } from '../../services/users/user.service';
import { SidebarService } from '../../services/sidebar/sidebar.service';
import { SharedDataService } from '../../services/shared-data/shared-data.service';

@Component({
    selector: 'login',
    templateUrl: './templates/login.html',
})
export class LoginComponent implements OnInit {
    /**
     * The user's email.
     *
     * @type {string}
     */
    public email = '';

    /**
     * The user's password.
     *
     * @type {string}
     */
    public password = '';

    /**
     * A flag indicating that authentication was unsuccessful.
     *
     * @type {boolean}
     */
    public error = false;

    /**
     * A flag indicating that the form has been submitted and we are
     * awaiting the response from the api.
     *
     * @type {boolean}
     */
    public submitting = false;

    protected redirectTo: string;

    constructor(
        protected authService: AuthService,
        protected router: Router,
        protected authEventBus: AuthEventBus,
        protected sidebarService: SidebarService,
        protected route: ActivatedRoute,
    ) {
        //
    }

    public ngOnInit (): void {
        this.route.queryParams
            .subscribe((params) => {
                this.redirectTo = params.redirect;
            });
    }

    /**
     * Attempt to sign the user in.
     *
     * @param {NgForm} form
     */
    public login(form: NgForm): void {
        this.error = false;

        if (form.valid) {
            this.submitting = true;

            this.authService.authenticate(this.email, this.password)
                .then(() => {
                    this.authEventBus.login.emit();

                    this.sidebarService.remove().getRootUrl()
                        .then((url) => {
                            console.log('root url', url);
                            this.router.navigate([this.redirectTo || url]);
                            this.submitting = false;
                        })
                        .catch(() => {
                            console.log('could not find root');
                            this.router.navigate([this.redirectTo || '/']);
                            this.submitting = false;
                        });
                })
                .catch(() => {
                    this.submitting = false;
                    this.error = true;
                });
        }
    }
}
