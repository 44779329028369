import {EventEmitter, Injectable} from '@angular/core';

@Injectable()
export class AppVersionEventBus {

    public webApp: EventEmitter<string> = new EventEmitter<string>();

    public api: EventEmitter<string> = new EventEmitter<string>();

}
