import {NgModule} from '@angular/core';
import {EllipsisComponent} from '../../components/animations/ellipsis.component';
import {AnimateLoadingDirective} from '../../directives/buttons/animate-loading.directive';

@NgModule({
    declarations: [
        EllipsisComponent,
        AnimateLoadingDirective
    ],
    exports: [
        EllipsisComponent,
        AnimateLoadingDirective
    ]
})
export class AnimationsModule {

}
