// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: true,
    app_url: "https://app.kestrel.com",
    api_url: "https://api.kestrel.com/api/1.0/",
    mfapi_url: "https://mfapi.kestrel.com/api/",
    socket_url: "https://api.kestrel.com",
    cdn_url: "https://cdn.kestrel.com",
    io_socket: "6001",
    maps_key: "AIzaSyBquQiaounKGP7Sfd14cigW6U7XA5UFVCc",
    environment: "local",
    jwt_cookie_name: "JWT_TOKEN",
    raven_url: "https://200fefd9b4f949cdb945d731bd39a1ce@sentry.io/253012"
};
