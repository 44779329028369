<aside class="sidebar" #sidebar>
    <div class="sidebar-content">
        <div class="sidebar-brand">
            <div class="sidebar-brand-inner">
                <ng-container *ngIf="logo">
                    <img
                        src="{{ logo }}"
                        class="img-fluid text-center"
                        style="display: inline-block; max-height: 120px;"
                    />
                </ng-container>
            </div>
        </div>

        <ul class="sidebar-toolbar">
            <li class="sidebar-toolbar-item">
                <a routerLink="/preferences"><i class="icon-tools"></i></a>
            </li>
            <li class="sidebar-toolbar-item">
                <a (click)="logout()" class="cursor-pointer"
                    ><i class="icon-switch"></i
                ></a>
            </li>
        </ul>

        <ul class="sidebar-nav" *ngIf="items && items.length > 0">
            <ng-container *ngFor="let item of items">
                <ng-container *ngIf="item.items && item.items.length > 0">
                    <li class="sidebar-nav-title">{{ item.title }}</li>

                    <li
                        *ngFor="let child of item.items"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: child.url === '/' }"
                    >
                        <a routerLink="{{ child.url }}">
                            <i class="icon {{ child.icon }}"></i>
                            <span>{{ child.title }}</span>
                        </a>
                    </li>
                </ng-container>

                <ng-container
                    *ngIf="item.items && item.items.length <= 0 && item.url"
                >
                    <li
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: item.url === '/' }"
                    >
                        <a routerLink="{{ item.url }}">
                            <i class="icon {{ item.icon }}"></i>
                            <span>{{ item.title }}</span>
                        </a>
                    </li>
                </ng-container>
            </ng-container>
        </ul>
    </div>
</aside>
