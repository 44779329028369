import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AccessLogService } from '../services/access-log/access-log.service';
import { filter } from 'rxjs/operators';

declare const window: any;

@Injectable()
export class LogAccessGuard  {

    constructor(private accessLogService: AccessLogService, private router: Router) {
        // The canActivate, canAccessChild, and canLoad guards are only
        // ran the first time that route is accessed and then it caches
        // the result. We want to log the route each time a route is
        // loaded so we listen to the router events instead.
        router.events.pipe(filter(event => event instanceof NavigationEnd))
              .subscribe((event: NavigationEnd) => {
                accessLogService.log(window.location.origin + event.url);
              });
    }

    /**
     * Log the access to the route.
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<boolean> | Promise<boolean> | boolean}
     */
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return true;
    }

}
