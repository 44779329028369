import {NgModule} from '@angular/core';
import {LoginComponent} from '../../components/login/login.component';
import {CommonModule} from '@angular/common';
import {LoginRoutingModule} from './login-routing.module';
import {SharedModule} from '../shared/shared.module';
import {AuthComponent} from '../../components/auth/auth.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        LoginRoutingModule
    ],
    declarations: [
        AuthComponent,
        LoginComponent
    ],
})
export class LoginModule {

}
