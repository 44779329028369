import { CatcherResponse } from '../types/CatcherResponse';
import { Extracted } from '../types/Extracted';

/**
 * Generates the response for the catcher() function.
 *
 * @param {Error|null} err
 * @param {T|null} val
 *
 * @return {CatcherResponse<T>}
 */
const catcherResponse = <T> (err: Error, val: T): CatcherResponse<T> => {
    const response: any = [err, val];
    response.err = err;
    response.val = val;
    return response;
};

/**
 * Converts a given promise to a standard format, regardless of
 * whether the promise fails or succeeds. Returns an object
 * that can be array or object destructured.
 * The following all get the same values in the error/value variables:
 *
 * @example
 * const [err, val] = await catcher();
 * const [error, value] = await catcher()
 * const { err, val } = await catcher();
 *
 * @param {Promise<T>} promise
 * @return {Promise<CatcherResponse<T>>}
 */
export const catcher = async <T> (promise: Promise<T>): Promise<CatcherResponse<T>> => {
    try {
        const val = await promise;
        return catcherResponse(null, val);
    } catch (err) {
        return catcherResponse(err, null);
    }
};

/**
 * Inner function for pipe and pipeRight.
 */
const _pipe = (fn1, fn2) => (...args) => fn2(fn1(...args));

/**
 * Takes a variable amount of functions and creates
 * a new function that pipes the given value through
 * all the functions.
 */
export const pipe = (...fns: Function[]): Function => fns.reduce(_pipe);

/**
 * Same as pipe, but uses the functions from right-to-left.
 */
export const pipeRight = (...fns: Function[]): Function => fns.reduceRight(_pipe);

/**
 * Takes a function and a default parameter. The return
 * value of the function will be returned if completed
 * successfully, otherwise the default value will be returned.
 *
 * @param {() => T} getter - The function used to get the value.
 * @param {T} def - The default value.
 * @return {T} - The return value.
 */
export const attempt = <T> (getter: () => T, def: T): T => {
    try {
        return getter();
    } catch (e) {
        return def;
    }
};

/**
 * Converts an array of objects that have identifiers to an array of identifiers.
 *
 * @param {T[]} arr
 *
 * @returns {string[]}
 */
export const identifiers = <T extends { identifier?: string }> (arr: T[]): string[] => {
    return arr.map(item => item.identifier);
};

/**
 * An identity function. Takes any argument and returns it, with an inferred type.
 * Used to force an array to be inferred as a tuple instead of an array.
 *
 * @param {T} val
 *
 * @returns {T}
 *
 * @example
 * // Assume that:
 * // - one() returns a number
 * // - two() returns a string
 *
 * const standard = [one(), two()]:
 * const identified = identity([one(), two()]);
 *
 * // `standard` has type (number | string)[]
 * // `identified` has type [number, string]
 */
export const identity = <T extends [void] | {}> (val: T): T => val;

/**
 * Takes any given value, and determines if it's truthy.
 *
 * @param {any} val
 *
 * @return {boolean}
 */
export const truthy = (val: any): boolean => !!val;

/**
 * Takes an object and an array of keys, and returns a new object consiting of only the given keys.
 *
 * @param {O} original - The object to build a new object from.
 * @param {K[]} keys - The keys to extract.
 * @param {M} [merge=null] - An optional object to merge into the new object.
 *
 * @returns {(Extracted<O, K> & M) | Extracted<O, K>} - The new object.
 */
export function extract <O extends object, K extends keyof O> (original: O, keys: K[]): Extracted<O, K>;
export function extract <O extends object, K extends keyof O, M extends object> (original: O, keys: K[], merge: M): Extracted<O, K> & M;
export function extract <
    O extends object,
    K extends keyof O,
    M extends object = {}
> (original: O, keys: K[], merge: M = null): (Extracted<O, K> & M) | Extracted<O, K> {
    const extracted = keys.reduce((obj, key) => {
        obj[key] = original[key];
        return obj;
    }, <Extracted<O, K>>{});

    if (merge) {
        return Object.assign({}, extracted, merge);
    }

    return extracted;
}

export const isArray = Array.isArray || (<T>(x: any): x is T[] => x && typeof x.length === 'number');

export function isNumeric(val: any): val is number {
    // parseFloat NaNs numeric-cast false positives (null|true|false|"")
    // ...but misinterprets leading-number strings, particularly hex literals ("0x...")
    // subtraction forces infinities to NaN
    // adding 1 corrects loss of precision from parseFloat (#15100)
    return !isArray(val) && (val - parseFloat(val) + 1) >= 0;
};