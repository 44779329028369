import { Injectable } from "@angular/core";
import { ApiService } from "./api-service";
import { environment } from "../../environments/environment";
import { HttpHeaders } from "@angular/common/http";

declare const window: any;

@Injectable()
export class CdnService extends ApiService {
    /**
     * The base CDN url.
     *
     * @type {string}
     */
    protected url: string = environment["cdn_url"];

    /**
     * Get the headers required for a json post request.
     *
     * @returns {RequestOptions}
     */
    protected getPostHeaders() {
        const headers = new HttpHeaders({
            Accept: "application/json",
            Authorization: "Bearer " + this.jwtService.get()
        });

        return { headers: headers };
    }
}
