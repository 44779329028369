<div class="gdpr">
    <img src="/assets/img/kestrel-portal-logo.png" alt="Kestrel" class="logo" />

    <div *ngIf="!loader" class="card">
        <div class="card-body">
            <p *ngIf="!questions">
                No questions found.
            </p>

            <ng-container *ngIf="questions">
                <p class="gutter-bottom">
                    Thank you for opting in! Please set your preferences below.
                </p>

                <form
                    id="form"
                    class="form-horizontal"
                    (ngSubmit)="submit($event)"
                >
                    <ng-container *ngFor="let key of questionKeys">
                        <div class="gutter-bottom">
                            <span class="help-block"
                                >{{ questions[key].question }}</span
                            >
                            <div class="row">
                                <ng-container
                                    *ngFor="let options of questions[key].options"
                                >
                                    <div
                                        class="option-column col-md-3 col-sm-6"
                                    >
                                        <ng-container
                                            *ngFor="let option of options"
                                        >
                                            <div class="checkbox">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        [checked]="isSelected(selected, option.identifier)"
                                                        [value]="option.identifier"
                                                        (change)="checkboxChange(option.identifier)"
                                                    />
                                                    {{ option.label }}
                                                </label>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </form>
            </ng-container>
            <p class="no-margin">
                To unsubscribe in future please contact us at
                <a href="mailto:enquiries@kestrel.com">enquiries@kestrel.com</a
                >.
            </p>
        </div>

        <div class="card-footer" *ngIf="questions">
            <div class="float-right">
                <button type="submit" form="form" class="btn btn-primary">
                    Save
                </button>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>

    <preloader *ngIf="loader"></preloader>
</div>
