import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {AuthEventBus} from '../../events/auth/auth.event.bus';
import {AuthService} from '../../services/auth/auth.service';
import {AuthenticatableUser} from '../../models/authenticatable-user';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import { Subscription } from 'rxjs';
import { HasSubscriptions } from '../has-subscriptions';

@Component({
    selector: 'login-modal',
    templateUrl: './templates/login-modal.html'
})
export class LoginModalComponent extends HasSubscriptions implements OnInit, OnDestroy, AfterViewInit {

    /**
     * The currently signed in user.
     *
     * @type {AuthenticatableUser}
     */
    public currentUser: AuthenticatableUser;

    /**
     * The password the user has supplied.
     */
    public password: string;

    /**
     * A flag indicating that the modal is being displayed.
     *
     * @type {boolean}
     */
    protected displaying = false;

    /**
     * The modal instance.
     */
    @ViewChild('loginModal', {static: false}) public modal: any;

    constructor(protected authEventBus: AuthEventBus, protected authService: AuthService, protected router: Router) {
        super();
    }

    ngOnInit(): void {
        this.authService.user()
            .then((user) => {
                if (user.email == undefined) {
                    this.router.navigate(['/login']);
                }

                this.currentUser = user;
            });
    }

    ngAfterViewInit(): void {
        this.modal.onShow.subscribe(() => {
            this.displaying = true;
        });

        this.modal.onHide.subscribe(() => {
            this.displaying = false;
        });

        this.subscriptions.push(
            this.authEventBus.tokenInvalid.subscribe(() => {
                if ( ! this.displaying) {
                    this.modal.show();
                }
            })
        );
    }

    /**
     * Attempt to log the user in.
     *
     * @param {NgForm} form
     */
    public login(form: NgForm): void {
        if (form.valid) {
            this.authService.authenticate(this.currentUser.email, this.password)
                .then(() => {
                    this.authEventBus.login.emit();
                    this.modal.hide();

                    this.password = null;
                    form.resetForm();
                })
                .catch(() => {
                    // this.error = true;
                });
        }
    }

}
