import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from '@angular/core';
import { JwtService } from '../services/jwt.service';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, flatMap } from 'rxjs/operators';
import { ApiService } from '../services/api-service';
import { Router } from '@angular/router';
import { AuthEventBus } from '../events/auth/auth.event.bus';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private jwt: JwtService,
        private api: ApiService,
        private router: Router,
        private authEventBus: AuthEventBus
    ) {}

    /**
     * Add the JWT to the request, if the request fails check if we can
     * refresh the JWT and then retry the request.
     *
     * @param {HttpRequest<any>} request
     * @param {HttpHandler} next
     * @returns {Observable<HttpEvent<any>>}
     */
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = this.addAuthorizationHeader(request);

        return next.handle(request)
                   .pipe(
                       catchError((error) => {
                           if (error.status === 401) {
                               if (error.error.message === 'Token has expired') {
                                   return this.api.newToken()
                                       .pipe(
                                           flatMap(response => {
                                               this.jwt.set(response.token);

                                               return next.handle(this.addAuthorizationHeader(request));
                                           }),
                                           catchError((e) => {
                                               this.tokenInvalid();

                                               return throwError(e);
                                           })
                                       );
                               }

                               this.tokenInvalid();
                           }

                           return throwError(error);
                       })
                   );
    }

    /**
     * Add the authorization header to the request.
     *
     * @param {HttpRequest<any>} request
     * @returns {HttpRequest<any>}
     */
    protected addAuthorizationHeader(request: HttpRequest<any>): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.jwt.get()}`,
            }
        });
    }

    /**
     * Handle the JWT being invalid.
     */
    protected tokenInvalid(): void {
        this.jwt.forget();
        this.authEventBus.tokenInvalid.emit();
    }
}