import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ValidEmailValidatorDirective} from '../../directives/validators/valid-email.directive';
import {UniqueValidatorDirective} from '../../directives/validators/unique.directive';
import {ConfirmedValidator} from '../../directives/validators/confirmed.directive';
import {ValidPhoneValidatorDirective} from '../../directives/validators/valid-phone.directive';
import {RequiredWithoutValidator} from '../../directives/validators/required-without.directive';
import {ValidSubdomainValidatorDirective} from '../../directives/validators/valid-subdomain.directive';
import {CurrencyInputComponent} from '../../components/forms/currency-input.component';
import {ValidationService} from '../../services/validation/validation.service';
import { NgxSelectModule } from './ngx-select.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
    ],
    declarations: [
        ValidEmailValidatorDirective,
        UniqueValidatorDirective,
        ConfirmedValidator,
        ValidPhoneValidatorDirective,
        RequiredWithoutValidator,
        ValidSubdomainValidatorDirective,
        CurrencyInputComponent,
    ],
    providers: [
        ValidationService
    ],
    exports: [
        CommonModule,
        FormsModule,
        ValidEmailValidatorDirective,
        UniqueValidatorDirective,
        ConfirmedValidator,
        ValidPhoneValidatorDirective,
        RequiredWithoutValidator,
        ValidSubdomainValidatorDirective,
        CurrencyInputComponent,
    ]
})

export class FormGroupModule {
}
