<div class="gdpr">
    <img src="/assets/img/kestrel-portal-logo.png" alt="Kestrel" class="logo" />

    <div *ngIf="!loader" class="card">
        <div class="card-body" *ngIf="!optedOut">
            <p>
                Do you still wish to receive sailing schedules, service
                notifications, and arrivals information via email?
            </p>
            <div class="text-center">
                <button
                    (click)="savePreferences(true)"
                    class="btn btn-default btn-success"
                >
                    Yes
                </button>
                <button
                    (click)="savePreferences(false)"
                    class="btn btn-default"
                >
                    No
                </button>
            </div>
        </div>

        <div class="card-body" *ngIf="optedOut">
            <p>You will no longer receive any emails from us.</p>
        </div>
    </div>

    <preloader *ngIf="loader"></preloader>
</div>
