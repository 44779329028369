import {NgZone} from '@angular/core';

export class PrivateChannel {
    protected eventMap = {
        notificationRead: 'KestrelSAAS\\Core\\Events\\NotificationRead'
    };

    constructor(private zone: NgZone, private channel: any) {
    }

    /**
     * Listen for the specified event on the channel, and then run
     * the callback.
     *
     * @param event
     * @param callback
     * @return {PrivateChannel}
     */
    public listen(event: string, callback: any): PrivateChannel {
        this.channel.listen(event, (result: any) => {
            this.zone.run(() => {
                callback(result);
            });
        });

        return this;
    }

    /**
     * Subscribe to the event.
     */
    public event(event: string, callback: any): PrivateChannel {
        event = this.eventMap[event];

        if ( ! event) {
            throw Error('That event has not been defined in the event map');
        }

        return this.listen(event, callback);
    }

    /**
     * Listen for the specified event on the channel, and then run
     * the callback.
     *
     * @param event
     * @param callback
     * @return {PrivateChannel}
     */
    public notification(callback: any): PrivateChannel {
        this.channel.notification((result: any) => {
            this.zone.run(() => {
                callback(result);
            });
        });

        return this;
    }
}
