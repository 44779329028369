import { Component, OnInit } from '@angular/core';
import { JwtService } from '../../services/jwt.service';
import { SessionStorageService } from '../../services/storage/session-storage.service';
import { HasSubscriptions } from '../has-subscriptions';
import { AuthEventBus } from '../../events/auth/auth.event.bus';
import { SharedDataService } from '../../services/shared-data/shared-data.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { GroupService } from '../../services/groups/group.service';
import { Group } from '../../models/group';
import { AuthenticatableUser } from '../../models/authenticatable-user';
import { TenantService } from '../../services/tenants/tenant.service';
import { WindowRef } from '../../utilities/window-ref';
import { LocalStorageService } from '../../services/storage/local-storage.service';

@Component({
    selector: 'system',
    templateUrl: './templates/system.component.html',
    providers: [
        GroupService,
        TenantService
    ]
})
export class SystemComponent extends HasSubscriptions implements OnInit {
    /**
     * All of the available groups.
     */
    public groups: Group[] = [];

    /**
     * The user we are currently viewing the system for.
     */
    public selectedGroup: Group;

    public currentUser: AuthenticatableUser;

    constructor(
        protected groupService: GroupService,
        protected tenantService: TenantService,
        protected authService: AuthService,
        protected sessionStorage: SessionStorageService,
        protected sharedData: SharedDataService,
        protected localStorage: LocalStorageService,
        protected jwtService: JwtService,
        protected window: WindowRef,
        protected authEventBus: AuthEventBus
    ) {
        super();
    }

    ngOnInit() {
        this.addSubscription(this.bindLogin());
        this.addSubscription(this.bindLogout());
        this.addSubscription(this.bindImpersonate());

        this.authService.user().then((user) => {
            this.currentUser = user;

            if (user.can('groups.access.all')) {
                this.groupService.all()
                    .then((groups) => {
                        this.groups = groups;

                        if (this.jwtService.get()) {
                            this.selectedGroup = this.groups.find(group => this.jwtService.decode().gid == group.identifier);
                        }
                    });
            }
        });
    }

    /**
     * Change the tenant database.
     */
    public switchGroup(group: Group) {
        this.tenantService.switch(group)
            .then((token) => {
                this.sessionStorage.clear();
                this.localStorage.clear();
                this.jwtService.forget();
                this.sharedData.remove();
                this.jwtService.set(token);

                this.window.getNativeWindow().location.reload();
            });
    }

    /**
     * Bind the login subscriptions.
     *
     * @returns {Subscription}
     */
    protected bindLogin(): Subscription {
        return this.authEventBus.login.subscribe(() => {
            this.sharedData.remove();
        });
    }

    /**
     * Bind the logout subscriptions.
     *
     * @returns {Subscription}
     */
    protected bindLogout(): Subscription {
        return this.authEventBus.logout.subscribe(() => {
            this.sessionStorage.clear();
            this.localStorage.clear();
            this.jwtService.forget();
            this.sharedData.remove();
            this.authService.forgetUser();
        });
    }

    /**
     * Bind the impersonation subscriptions.
     *
     * @returns {Subscription}
     */
    protected bindImpersonate(): Subscription {
        return this.authEventBus.impersonate.subscribe(() => {
            this.sessionStorage.remove('sidebar');
            this.sharedData.remove();
            this.authService.forgetUser();
        });
    }
}
