import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AppVersionEventBus } from "../../events/system/app-version.event.bus";
import { Subscription } from "rxjs";

@Component({
    selector: "app-version",
    templateUrl: "./templates/app-version.html"
})
export class AppVersionComponent implements OnInit, OnDestroy {
    /**
     * The modal instance.
     */
    @ViewChild("appUpdatedModal", { static: false }) public modal: any;

    protected subscriptions: Subscription[] = [];

    constructor(protected appVersionEventBus: AppVersionEventBus) {}

    ngOnInit(): void {
        this.subscriptions.push(
            this.appVersionEventBus.webApp.subscribe(() => {
                this.modal.show();
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }

    /**
     * Refresh the page.
     */
    public refresh(): void {
        location.reload();
    }
}
