<div
    bsModal
    #timezoneSelectorModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="timezoneSelectorModal"
    aria-hidden="true"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header text-center">
                <h3>Welcome, you do not have a time zone set</h3>
            </div>
            <div class="modal-body" *ngIf=" ! selectingTimezone">
                <div class="text-center" *ngIf="guessedTimezone">
                    <p class="half-gutter-bottom">
                        We have detected your timezone is
                    </p>
                    <h4 class="half-gutter-bottom">
                        {{ guessedTimezone.name }}
                    </h4>

                    <p>Do you want to set this as your timezone?</p>
                    <p class="gutter-bottom">
                        Your timezone can be changed at any time in your
                        preferences page
                    </p>

                    <button
                        type="button"
                        class="btn btn-sm btn-primary"
                        (click)="confirmTimezone()"
                    >
                        Confirm Timezone
                    </button>
                    <button
                        type="button"
                        class="btn btn-sm btn-default"
                        (click)="selectTimezone()"
                    >
                        Select a Different Timezone
                    </button>
                </div>
            </div>

            <div
                class="modal-body"
                *ngIf="selectingTimezone && groups.length > 0"
            >
                <div class="text-center">
                    <p class="gutter-bottom">
                        Please select your region and timezone, and then click
                        the set timezone button below
                    </p>
                </div>

                <div class="form-group">
                    <label for="region" class="col-form-label"
                        >Select Your Region</label
                    >
                    <select
                        name="region"
                        id="region"
                        class="form-control"
                        [(ngModel)]="selectedGroupIdentifier"
                        (ngModelChange)="setGroup()"
                    >
                        <option value="">Select Your Region</option>
                        <option
                            *ngFor="let group of groups"
                            [value]="group.identifier"
                            >{{ group.label }}</option
                        >
                    </select>
                </div>

                <div class="form-group" *ngIf="selectedGroup">
                    <label for="region" class="col-form-label"
                        >Select Your Timezone</label
                    >
                    <select
                        name="timezone"
                        id="timezone"
                        class="form-control"
                        [(ngModel)]="selectedTimezoneIdentifier"
                        (ngModelChange)="setTimezone()"
                    >
                        <option value="">Select Your Timezone</option>
                        <option
                            *ngFor="let timezone of selectedGroup.timezones"
                            [value]="timezone.identifier"
                            >{{ timezone.label }}</option
                        >
                    </select>
                </div>

                <div class="text-center">
                    <button
                        type="button"
                        class="btn btn-primary"
                        [disabled]=" ! selectedGroup || ! selectedTimezone"
                        (click)="saveTimezone()"
                    >
                        Set Timezone
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
