import {Injectable} from '@angular/core';
import {ApiService} from '../api-service';
import {Timezone} from '../../models/timezone';
import {TimezoneGroup} from '../../models/timezone-group';

@Injectable()
export class TimezoneService {

    constructor(private apiService: ApiService) {}

    /**
     * Find a timezone by its name.
     *
     * @param name
     * @returns {Promise<Timezone>}
     */
    public find(name: string): Promise<Timezone> {
        return this.apiService.post('timezones/find', {
                timezone: name
            })
            .toPromise()
            .then((response: Object) => {
                return response as Timezone;
            })
            .catch(this.apiService.handleError);
    }

    /**
     * Get all of the timezone groups.
     *
     * @returns {Promise<TimezoneGroup>}
     */
    public groups(): Promise<TimezoneGroup[]> {
        return this.apiService.get('timezones/groups')
            .toPromise()
            .then((response: Object) => {
                return response as TimezoneGroup[];
            })
            .catch(this.apiService.handleError);
    }

    /**
     * Guess the user's timezone from their offset to UTC.
     *
     * @returns {Promise<TResult|TResult2|Timezone>}
     */
    public guessTimezone(): Promise<Timezone> {
        const date = new Date;
        const request = {
            offset: date.getTimezoneOffset()
        };

        return this.apiService.post('timezones/guess', request)
            .toPromise()
            .then((response: Object) => {
                return response as Timezone;
            })
            .catch(this.apiService.handleError);
    }

}
