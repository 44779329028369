<div class="notification-bar-tab">
    <a href="#" class="toggle-notification-bar">
        <i class="icon-alarm"></i>
        <div *ngIf="user">
            <div class="badge badge-info badge-circle sup" *ngIf="total">
                {{ total }}
            </div>
        </div>
    </a>
</div>

<div class="notification-bar-inner">
    <div class="notification-bar-filters">
        <input type="text" class="form-control" [formControl]="terms" placeholder="Search...">
    </div>

    <div
        class="notification-bar-content"
        infiniteScroll
        [scrollWindow]="false"
        (scrolled)="onScroll()"
    >
        <preloader *ngIf="showPreLoader"></preloader>

        <div *ngIf="user">
            <div class="notification" [ngClass]="{
            'notification-default': ! notification.task,
            'notification-warning': notification.type === 'KestrelSAAS\\Core\\Notifications\\NewEnquiry',
            'notification-success': notification.type === 'KestrelSAAS\\Core\\Notifications\\NewReply',
            'notification-danger': notification.type === 'KestrelSAAS\\Core\\Notifications\\ChaseUpSalesEnquiry' || notification.type === 'KestrelSAAS\\CRM\\Notifications\\OpportunityExpiring'
        }" (click)="markAsRead(notification)" *ngFor="let notification of notifications;">
                <div class="notification-icon">
                    <div class="badge badge-circle badge-lg" [ngClass]="{
                        'badge-primary': ! notification.task,
                        'badge-warning': notification.type === 'KestrelSAAS\\Core\\Notifications\\NewEnquiry',
                        'badge-success': notification.type === 'KestrelSAAS\\Core\\Notifications\\NewReply',
                        'badge-danger': notification.type === 'KestrelSAAS\\Core\\Notifications\\ChaseUpSalesEnquiry' || notification.type === 'KestrelSAAS\\CRM\\Notifications\\OpportunityExpiring'
                    }">
                        <i [ngClass]="notification.icon ? notification.icon : 'icon-alarm'"></i>
                    </div>
                </div>
                <div class="notification-body">
                    <strong *ngIf="notification.title">{{ notification.title }}</strong><br>
                    <span [innerHtml]="parse(notification.body)"></span><br>
                    <small class="text-muted">{{ notification.created_at_difference }}</small>
                </div>
            </div>
        </div>
        <div class="container-fluid text-center" *ngIf="user && ! showPreLoader">
            <p class="half-gutter-top" *ngIf="! notifications || ! notifications.length">
                <span *ngIf=" ! query">
                    You currently have no notifications
                </span>
                <span *ngIf="query">
                    Couldn't find any notifications matching "{{ query}}"
                </span>
            </p>
        </div>
    </div>
    <div class="notification-bar-footer">
        <a class="notification-bar-footer-btn" (click)="hide()" routerLink="/notification-history">
            View History
        </a>
    </div>
</div>