// Lib
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// Services
import { GdprService } from '../../services/gdpr/gdpr.service';
import { Notifier } from '../../services/notifier/notifier';

// Contracts, Models & Types
import { ClientTypeCategory } from '../../models/client-type-category';
import { Questions } from '../../types/GDPR/Questions';
import { Option } from '../../types/GDPR/Option';

// Helpers
import { has } from '../../helpers/objects';
import { flatten } from '../../helpers/arrays';

@Component({
    selector: 'gdpr-preferences',
    templateUrl: './templates/gdpr-preferences.html',
})
export class GdprPreferencesComponent implements OnInit {
    /**
     * Flag to determine if the loader should be shown.
     */
    public loader = true;

    /**
     * The question keys in the order they should be rendered.
     */
    questionKeys = ['regions', 'cargo_type', 'importexport', 'freight_forwardershipper'];

    /**
     * All the questions, from the server.
     */
    public questions: Questions;

    /**
     * Array of all options that have been checked by the user.
     */
    selected: string[] = [];

    /**
     * The UUID from the route.
     */
    protected uuid: string;

    /**
     * The signature from the route.
     */
    protected signature: string;

    /**
     * Object where keys are the names of region groups,
     * and values are an array of actual regions that
     * should be included in the group.
     */
    protected regionGroups = {
        oceania: ['australasia'],
        asia_middleeast: ['far_east', 'indian_sub_continent', 'middle_east'],
        africa: ['east_africa', 'north_africa_inc_portugalmorocco', 'south_africa', 'west_africa'],
        europe: ['europe', 'mediterranean', 'russia', 'scandinavian_region'],
        the_americas: ['canada', 'north_america', 'south_america', 'the_caribbean'],
    };

    /**
     * The actual regions, returned from the API.
     */
    protected originalRegions: Option[][];

    /**
     * The made-up regions that act as categories.
     */
    protected newRegions: Option[][] = [
        [
            {
                identifier: 'oceania',
                name: '',
                label: 'Oceania',
            },
            {
                identifier: 'asia_middleeast',
                name: '',
                label: 'Asia/Middle East',
            },
            {
                identifier: 'africa',
                name: '',
                label: 'Africa',
            },
            {
                identifier: 'europe',
                name: '',
                label: 'Europe',
            },
            {
                identifier: 'the_americas',
                name: '',
                label: 'The Americas',
            },
        ],
    ];

    /**
     * Constructs the class
     *
     * @param {ActivatedRoute} route
     * @param {GdprService} gdpr
     * @param {Notifier} notifier
     */
    constructor (
        protected route: ActivatedRoute,
        protected gdpr: GdprService,
        protected notifier: Notifier,
    ) {}

    /**
     * Initiates the class.
     */
    public ngOnInit (): void {
        const params = this.route.snapshot.paramMap;
        this.uuid = params.get('uuid');
        this.signature = params.get('signature');

        const promises: [Promise<string[]>, Promise<Questions>] = [
            this.gdpr.getPreferences(this.uuid, this.signature),
            this.gdpr.getQuestions(),
        ];

        Promise.all(promises)
            .then(([selected, questions]) => {
                this.originalRegions = questions.regions.options;
                questions.regions.options = this.newRegions;
                this.questions = questions;

                const flattenedOriginalRegions = flatten(this.originalRegions);
                Object.keys(this.regionGroups).forEach((group) => {
                    const names = this.regionGroups[group];
                    const ids = names.map(name => flattenedOriginalRegions.find(r => r.name === name).identifier);

                    if (ids.every(id => this.isSelected(this.selected, id))) {
                        selected = selected.filter(id => !this.isSelected(ids, id));
                        selected.push(group);
                    }
                })

                // Can't use Object.entries in firefox
                // Object.entries(this.regionGroups).forEach(([group, names]) => {
                //     const ids = names.map(name => flattenedOriginalRegions.find(r => r.name === name).identifier);
                //
                //     if (ids.every(id => selected.includes(id))) {
                //         selected = selected.filter(id => !ids.includes(id));
                //         selected.push(group);
                //     }
                // });

                this.selected = selected;

                this.loader = false;
            })
            .catch((err) => {
                this.failedToLoadQuestions();

                console.log(err);
            });
    }

    /**
     * Handles the form submit event.
     *
     * @param {Event} e
     */
    public submit (e: Event): void {
        // Show the loader and prevent the default event
        e.preventDefault();
        this.loader = true;

        const { uuid, signature, selected } = this;

        // Create a function to check if the given identifier is a region group key.
        const isRegionGroup = has(this.regionGroups);

        // Get all the actual regions as an array.
        const flattenedRegions = flatten(this.originalRegions);

        // Convert the groups in the `selected` array into the groups values.
        const ungroupedSelected = selected.reduce((acc, identifier) => {
            if (isRegionGroup(identifier)) {
                acc.push(...this.regionGroups[identifier].map((name) => {
                    return flattenedRegions.find(op => op.name === name).identifier;
                }));
            } else {
                acc.push(identifier);
            }
            return acc;
        }, []);

        // Update the preferences
        this.gdpr.updatePreferences(uuid, signature, ungroupedSelected)
            .then(() => this.successfullyUpdatedPreferences())
            .catch(() => this.failedToSavePreferences())
            .then(() => this.loader = false);
    }

    /**
     * Handles a checkbox being checked.
     *
     * @param {string} identifier
     */
    public checkboxChange (identifier: string): void {
        if (this.isSelected(this.selected, identifier)) {
            this.selected = this.selected.filter(itm => itm !== identifier);
        } else {
            this.selected.push(identifier);
        }
    }

    /**
     * Check if the identifier is selected.
     *
     * @param {string} identifier
     * @returns {boolean}
     */
    public isSelected(selected: Array<string>, identifier: string): boolean {
        const index = selected.indexOf(identifier);

        return index > -1;
    }

    /**
     * Shows an 'update preferences' success modal.
     */
    protected successfullyUpdatedPreferences (): void {
        this.notifier.success({
            title: 'Updated Preferences',
            body: 'Your preferences were successfully updated.',
        });
    }

    /**
     * Shows a 'failed to load questions' error
     */
    protected failedToLoadQuestions (): void {
        this.notifier.error({
            title: 'An Error Occurred',
            body: 'Failed to load available questions, try again later. If the problem persists, contact the technical team.',
        });
    }

    /**
     * Shows a 'failed to save preferences' error
     */
    protected failedToSavePreferences (): void {
        this.notifier.error({
            title: 'An Error Occurred',
            body: 'Failed to save your preferences, try again later. If the problem persists, contact the technical team.',
        });
    }
}
