import { Injectable } from '@angular/core';
import { ApiService } from '../api-service';
import { SharedDataService } from '../shared-data/shared-data.service';
import { JwtService } from '../jwt.service';
import { AuthenticatableUser } from '../../models/authenticatable-user';
import { User } from '../../models/user';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class BannerBarService extends ApiService {
    /**
     * The current user.
     */
    protected currentUser: AuthenticatableUser;

    /**
     * Flag to determine if the current user is impersonating another user.
     */
    protected isImpersonating: boolean;

    constructor (
        protected jwtService: JwtService,
        protected http: HttpClient,
        protected sharedDataService: SharedDataService,
    ) {
        super(jwtService, http);
    }

    /**
     * Determines whether the current user is impersonating or not.
     *
     * @returns {boolean}
     */
    public async impersonating (): Promise<boolean> {
        if (typeof this.isImpersonating !== 'boolean') {
            this.isImpersonating = await this.sharedDataService.get('impersonating', false);
        }

        return this.isImpersonating;
    }

    /**
     * Gets the current user.
     *
     * @returns {AuthenticatableUser}
     */
    public async user (): Promise<AuthenticatableUser> {
        if (!this.currentUser) {
            const user = await this.sharedDataService.get<User>('user');

            this.setUser(user);
        }

        return this.currentUser;
    }

    /**
     * Sets the current user.
     *
     * @param {User} user
     */
    public setUser (user: User): void {
        this.currentUser = new AuthenticatableUser(user, user.permissions);
    }
}
