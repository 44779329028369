import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { JwtService } from "./jwt.service";
import { TokenResponse } from "../contracts/responses/token.response";
import { URLWithParts } from "../types/URLBuilder/URLWithParts";
import { URLWithPath } from "../types/URLBuilder/URLWithPath";
import { environment } from "../../environments/environment";

/***********************************
 * ========== IMPORTANT ========== *
 ***********************************
 * If you're adding parameters to the method
 * functions (post, get, etc.), make sure the
 * `retryOnFail` parameter remains last, which
 * allows the retry handler method to work
 * properly. The new parameters should also be
 * added to the array that is passed to `retry()`.
 * You can't use the `arguments` object to try and
 * get all the arguments, as we **do not** need the
 * retryOnFail in `retry()`, but as the retryOnFail
 * parameter has a default value, it's not always in
 * the `arguments` object.
 */

@Injectable()
export class ApiService {
    /**
     * The base API url.
     *
     * @type {string}
     */
    protected url: string = environment.api_url;

    /**
     * The url to hit to refresh the JWT token.
     *
     * @type {string}
     */
    protected refreshUrl = "authenticate/refresh";

    constructor(protected jwtService: JwtService, protected http: HttpClient) {}

    /**
     * Performs a request with `get` http method.
     */
    public get<R = Object>(url: string): Observable<R> {
        url = this.getAbsoluteUrl(url);

        return this.http.get<R>(url, this.getGetHeaders());
    }

    /**
     * Performs a request with `post` http method.
     */
    public post<R = Object>(
        url: string,
        body: any,
        retryOnFail: boolean = true,
        headers: { [key: string]: string } = null
    ): Observable<R> {
        url = this.getAbsoluteUrl(url);

        return this.http.post<R>(url, body, this.getPostHeaders(headers));
    }

    /**
     * Performs a request with `put` http method.
     */
    public put<R = Object>(
        url: string,
        body: any,
        retryOnFail: boolean = true,
        headers: { [key: string]: string } = null
    ): Observable<R> {
        url = this.getAbsoluteUrl(url);

        return this.http.put<R>(url, body, this.getPostHeaders(headers));
    }

    /**
     * Performs a request with `delete` http method.
     */
    public delete<R = Object>(
        url: string,
        retryOnFail: boolean = true
    ): Observable<R> {
        url = this.getAbsoluteUrl(url);

        return this.http.delete<R>(url, this.getGetHeaders());
    }

    /**
     * Performs a request with `patch` http method.
     */
    public patch<R = Object>(
        url: string,
        body: any,
        retryOnFail: boolean = true,
        headers: { [key: string]: string } = null
    ): Observable<R> {
        url = this.getAbsoluteUrl(url);

        return this.http.patch<R>(url, body, this.getPostHeaders(headers));
    }

    /**
     * Performs a request with `head` http method.
     */
    public head<R = Object>(
        url: string,
        retryOnFail: boolean = true
    ): Observable<R> {
        url = this.getAbsoluteUrl(url);

        return this.http.head<R>(url, this.getGetHeaders());
    }

    /**
     * Performs a request with `options` http method.
     */
    public options<R = Object>(
        url: string,
        retryOnFail: boolean = true
    ): Observable<R> {
        url = this.getAbsoluteUrl(url);

        return this.http.options<R>(url, this.getGetHeaders());
    }

    /**
     * If the URL is a relative url attach the default API domain.
     *
     * @param {string} url
     * @returns {string}
     */
    protected getAbsoluteUrl(url: string): string {
        if (!url.startsWith("http") && !url.startsWith("//")) {
            url = this.buildUrl(url);
        }

        return url;
    }

    /**
     * Build the full url to be posted.
     *
     * @param {string|Object} url
     * @returns {string}
     */
    public buildUrl(url: string | URLWithPath | URLWithParts): string {
        if (typeof url === "string") {
            return this.url + url.trim();
        }

        return (
            this.url.replace(/\/$/, "") +
            "/" +
            (this.isUrlWithPath(url)
                ? url.path.replace(/^\//, "")
                : url.parts.join("/")) +
            (url.query ? this.toQueryString(url.query) : "")
        );
    }

    /**
     * Determines if the given url is with a path, not with parts.
     *
     * @param {URLWithPath|URLWithParts} url
     * @return {boolean}
     */
    private isUrlWithPath(url: URLWithPath | URLWithParts): url is URLWithPath {
        return typeof (<URLWithPath>url).path === "string";
    }

    /**
     * Converts an object into a query string.
     *
     * @param {Object} query
     * @return {string}
     */
    public toQueryString(query: { [key: string]: any }): string {
        const keys = Object.keys(query);
        return keys.length < 1
            ? ""
            : "?" +
                  keys
                      .map(key => {
                          let val = query[key];

                          if (typeof val === "object") {
                              val = JSON.stringify(val);
                          }

                          return `${encodeURIComponent(
                              key
                          )}=${encodeURIComponent(val)}`;
                      })
                      .join("&");
    }

    /**
     * Get the headers required for a GET request.
     *
     * @returns {Object}
     */
    protected getGetHeaders() {
        const headers = new HttpHeaders({});

        return { headers };
    }

    /**
     * Get the headers required for a json post request.
     *
     * @returns {Object}
     */
    protected getPostHeaders(headersObj: { [key: string]: string } = null) {
        const headers =
            headersObj ||
            new HttpHeaders({
                Accept: "application/json",
                "Content-Type": "application/json"
            });

        return { headers };
    }

    /**
     * Generate a new JWT.
     *
     * @returns {Promise<Object>}
     */
    public newToken(): Observable<any> {
        const url = this.buildUrl("authenticate/refresh");

        return this.http.get<TokenResponse>(url, this.getGetHeaders());
    }

    /**
     * Get a new token as a promise.
     *
     * @returns {Promise<any>}
     */
    public newTokenAsPromise(): Promise<TokenResponse> {
        if (!this.jwtService.get()) {
            return Promise.reject("Cannot refresh a non-existant token.");
        }

        const url = this.buildUrl(this.refreshUrl);
        return this.http
            .get<TokenResponse>(url, this.getGetHeaders())
            .toPromise()
            .then(res => this.setJwt(res));
    }

    /**
     * Set a new JWT token from a response.
     * Returns the same response it was given.
     *
     * @param {TokenResponse} response
     * @returns {TokenResponse}
     */
    protected setJwt(response: TokenResponse): TokenResponse {
        this.jwtService.set(response.token);

        return response;
    }

    /**
     * Handle an error message from an API request.
     *
     * @param error
     * @returns {Promise<void>|Promise<T>}
     */
    public handleError(error: any): Promise<any> {
        console.error("An error occurred", error); // for demo purposes only

        return Promise.reject(error);
    }
}
