import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import {SidebarService} from '../../services/sidebar/sidebar.service';
import {AuthEventBus} from '../../events/auth/auth.event.bus';
import {Router} from '@angular/router';
import * as $ from 'jquery';
import { AppVersionEventBus } from '../../events/system/app-version.event.bus';
import { SessionStorageService } from '../../services/storage/session-storage.service';
import { HasSubscriptions } from '../has-subscriptions';

const toggleSidebar = function(e) {
    e.preventDefault();

    $('body').toggleClass('sidebar-toggled');
};

@Component({
    selector: 'sidebar',
    templateUrl: './templates/sidebar.html'
})
export class SidebarComponent extends HasSubscriptions implements OnInit, AfterViewInit, OnDestroy {
    /**
     * The sidebar items to show.
     */
    public items: any[] = [];

    /**
     * The path to the logo.
     */
    public logo: string;

    /**
     * The perfect scrollbar instance.
     */
    public scrollbar: any;

    /**
     * The sidebar element.
     */
    @ViewChild('sidebar', {static: false}) public sidebar: ElementRef;

    constructor (
        protected sidebarService: SidebarService,
        protected authEventBus: AuthEventBus,
        protected appVersionEventBus: AppVersionEventBus,
        protected sessionStorage: SessionStorageService,
        protected router: Router
    ) {
        super();
    }

    ngOnInit (): void {
        this.sidebarService.getItems()
            .then((items) => {
                this.items = items;
            });

        this.sidebarService.getLogo()
            .then((logo) => {
                this.logo = logo;
            });

        this.addSubscription(
            this.authEventBus.login.subscribe(() => {
                // Remove any items already cached in the sidebar service or
                // session storage.
                this.sidebarService.remove();
            })
        );

        this.addSubscription(
            this.authEventBus.logout.subscribe(() => {
                // Remove any items already cached in the sidebar service or
                // session storage.
                this.sidebarService.remove();
            })
        );

        this.addSubscription(
            this.appVersionEventBus.webApp.subscribe(() => {
                // Remove items from session storage
                this.sidebarService.remove();
            })
        );
    }

    ngAfterViewInit() {
        this.scrollbar = new PerfectScrollbar(this.sidebar.nativeElement);

        $(document).on('click', '.toggle-sidebar', toggleSidebar);
    }

    ngOnDestroy(): void {
        if (this.scrollbar) {
            this.scrollbar.destroy();
            this.scrollbar = null;
        }

        $(document).off('click', '.toggle-sidebar', toggleSidebar);

        super.ngOnDestroy();
    }

    /**
     * Sign the user out.
     */
    public logout () {
        this.authEventBus.logout.emit();
        this.router.navigateByUrl('login')
            .catch(() => {});
    }
}
