/*
 * Each key in this object is typed as a
 * string literal with itself as a value.
 * This makes it easier to see what these
 * values are in storage in your IDE.
 */
export const SESSION_STORAGE_KEYS = {
    SIDEBAR: <'sidebar'>'sidebar',
    ACTION: <'action'>'action',
    OPPORTUNITY: {
        ROUTE: <'opportunity.route'>'opportunity.route',
        COMPANY: <'opportunity.company'>'opportunity.company',
        MOVEMENT_TYPE: <'opportunity.movement_type'>'opportunity.movement_type',
        FREIGHT: <'opportunity.freight'>'opportunity.freight',
    },
    PATH_STORAGE: {
        PREFIX: <'path.storage_'>'path.storage_',
        SECTION_PREFIX: <'path.storage.section'>'path.storage.section',
    },
    CLIENT_TYPES: {
        TAB: <'client_types.tab'>'client_types.tab',
    },
    LEAD_TYPES: {
        TAB: <'lead_types.tab'>'lead_types.tab',
    },
    FILTERS: {
        // Opportunities
        ALL_OPPORTUNITIES: <'filters.opportunities.all'>'filters.opportunities.all',
        ARCHIVED_OPPORTUNITIES: <'filters.opportunities.archived'>'filters.opportunities.archived',

        // Leads
        ALL_LEADS: <'filters.leads.all'>'filters.leads.all',
        ARCHIVED_LEADS: <'filters.leads.archived'>'filters.leads.archived',

        // Clients
        ALL_CLIENTS: <'filters.clients.all'>'filters.clients.all',
        ARCHIVED_CLIENTS: <'filters.clients.archived'>'filters.clients.archived',

        // Contacts
        ALL_CONTACTS: <'filters.contacts.all'>'filters.contacts.all',

        // Sales Queue
        ALL_SALES_QUEUE: <'filters.sales_queue.all'>'filters.sales_queue.all',

        // Rates
        ALL_RATES: <'filters.rates.all'>'filters.rates.all',
        EXPIRING_RATES: <'filters.rates.expiring'>'filters.rates.expiring',

        // Feeders
        ALL_FEEDERS: <'filters.feeders.all'>'filters.feeders.all',
        EXPIRING_FEEDERS: <'filters.feeders.expiring'>'filters.feeders.expiring',
        ARCHIVED_FEEDERS: <'filters.feeders.archived'>'filters.feeders.archived',

        // Lead Types
        ALL_LEAD_TYPES: <'filters.lead_types.all'>'filters.lead_types.all',
        ARCHIVED_LEAD_TYPES: <'filters.lead_types.archived'>'filters.lead_types.archived',

        // Lead Type Categories
        ALL_LEAD_TYPE_CATEGORIES: <'filters.lead_type_categories.all'>'filters.lead_type_categories.all',
        ARCHIVED_LEAD_TYPE_CATEGORIES: <'filters.lead_type_categories.archived'>'filters.lead_type_categories.archived',

        // Ports
        ALL_PORTS: <'filters.ports.all'>'filters.ports.all',
        ARCHIVED_PORTS: <'filters.ports.archived'>'filters.ports.archived',
    },
    SORTS: {
        // Opportunities
        ALL_OPPORTUNITIES: <'sorts.opportunities.all'>'sorts.opportunities.all',
        ARCHIVED_OPPORTUNITIES: <'sorts.opportunities.archived'>'sorts.opportunities.archived',

        // Leads
        ALL_LEADS: <'sorts.leads.all'>'sorts.leads.all',
        ARCHIVED_LEADS: <'sorts.leads.archived'>'sorts.leads.archived',

        // Clients
        ALL_CLIENTS: <'sorts.clients.all'>'sorts.clients.all',
        ARCHIVED_CLIENTS: <'sorts.clients.archived'>'sorts.clients.archived',

        // Contacts
        ALL_CONTACTS: <'sorts.contacts.all'>'sorts.contacts.all',

        // Sales Queue
        ALL_SALES_QUEUE: <'sorts.sales_queue.all'>'sorts.sales_queue.all',

        // Rates
        ALL_RATES: <'sorts.rates.all'>'sorts.rates.all',
        EXPIRING_RATES: <'sorts.rates.expiring'>'sorts.rates.expiring',

        // Feeders
        ALL_FEEDERS: <'sorts.feeders.all'>'sorts.feeders.all',
        EXPIRING_FEEDERS: <'sorts.feeders.expiring'>'sorts.feeders.expiring',
        ARCHIVED_FEEDERS: <'sorts.feeders.archived'>'sorts.feeders.archived',

        // Lead Types
        ALL_LEAD_TYPES: <'sorts.lead_types.all'>'sorts.lead_types.all',
        ARCHIVED_LEAD_TYPES: <'sorts.lead_types.archived'>'sorts.lead_types.archived',

        // Lead Type Categories
        ALL_LEAD_TYPE_CATEGORIES: <'sorts.lead_type_categories.all'>'sorts.lead_type_categories.all',
        ARCHIVED_LEAD_TYPE_CATEGORIES: <'sorts.lead_type_categories.archived'>'sorts.lead_type_categories.archived',

        // Ports
        ALL_PORTS: <'sorts.ports.all'>'sorts.ports.all',
        ARCHIVED_PORTS: <'sorts.ports.archived'>'sorts.ports.archived',
    },
};
