import { Component  } from '@angular/core';

@Component({
    selector: 'preloader',
    templateUrl: './templates/preloader.html',
    // template: require('./templates/preloader.html'),
})

export class PreloaderComponent {

}
