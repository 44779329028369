import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {NgxSelectComponent} from '../../components/ngx-select/ngx-select.component';
import {CommonModule} from '@angular/common';
import {AnimationsModule} from './animations.module';

@NgModule({
    imports: [
        ReactiveFormsModule,
        CommonModule,
        AnimationsModule
    ],
    declarations: [
        NgxSelectComponent,
    ],
    exports: [
        NgxSelectComponent,
        AnimationsModule
    ]
})

export class NgxSelectModule {
}
