import {NgModule} from '@angular/core';
import {SidebarComponent} from '../../components/sidebar/sidebar.component';
import {NotificationBarComponent} from '../../components/notification-bar/notification-bar.component';
import {SystemComponent} from '../../components/system/system.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {SharedModule} from './shared.module';
import {TimezoneSelectorComponent} from '../../components/timezone-selector/timezone-selector.component';
import {TimezoneService} from '../../services/timezone/timezone.service';
import {UserService} from '../../services/users/user.service';
import {BannerBarComponent} from '../../components/banner-bar/banner-bar.component';
import {BannerBarService} from '../../services/banner-bar/banner-bar.service';
import {SidebarService} from '../../services/sidebar/sidebar.service';
import {AppVersionComponent} from '../../components/app-version/app-version.component';
import {DesktopNotificationService} from '../../services/desktop-notifications/desktop-notification.service';
import {AuthModule} from './auth.module';
import {LoginModalComponent} from '../../components/login/login-modal.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from '../../http-interceptors/jwt-interceptor';
import { ReactiveFormsModule } from '@angular/forms';
import { BROWSER_GLOBAL_PROVIDERS } from '../../utilities/window-ref';

@NgModule({
    imports: [
        SharedModule,
        AuthModule,
        InfiniteScrollModule,
        ReactiveFormsModule,
    ],
    declarations: [
        SystemComponent,
        SidebarComponent,
        NotificationBarComponent,
        TimezoneSelectorComponent,
        BannerBarComponent,
        AppVersionComponent,
        LoginModalComponent,
    ],
    exports: [
        SharedModule,
        AuthModule,
        InfiniteScrollModule,
        SystemComponent,
        SidebarComponent,
        NotificationBarComponent,
        BannerBarComponent,
        AppVersionComponent,
        LoginModalComponent,
    ],
    providers: [
        TimezoneService,
        UserService,
        BannerBarService,
        SidebarService,
        DesktopNotificationService,
        ...BROWSER_GLOBAL_PROVIDERS,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
    ]
})
export class SystemModule {

}
