import { Injectable } from '@angular/core';

import { NotificationArguments } from '../../types/NotificationArguments';
import {SidebarService} from '../sidebar/sidebar.service';

declare const Notification: any;

/**
 * Constants to avoid typos when checking
 * strings.
 */
const NOTIFICATION = 'Notification';
const GRANTED = 'granted';
const DENIED = 'denied';

@Injectable()
export class DesktopNotificationService {
    /**
     * Flag to determine if notifications
     * should be sent or not.
     *
     * @var {boolean}
     */
    private shouldSendNotifications = false;

    /**
     * The path to the icon to use for notifications.
     *
     * @var {string}
     */
    private icon: string;

    /**
     * Initiates the class.
     */
    constructor (private sidebarService: SidebarService) {
        sidebarService.getLogo()
            .then((logo) => {
                this.icon = logo;
            })
            .catch(() => {
                this.icon = document.getElementsByClassName('sidebar-brand-inner')[0]
                    .getElementsByTagName('img')[0]
                    .src;
            });

        if (this.hasNotificationSupport()) {
            if (this.hasPermission()) {
                this.shouldSendNotifications = true;
            } else if ( ! this.hasDeniedPermission()) {
                this.getPermission();
            }
        }
    }

    /**
     * Determines if the browser has
     * notification support.
     *
     * @return {boolean}
     */
    private hasNotificationSupport (): boolean {
        return NOTIFICATION in window;
    }

    /**
     * Determines if the user has granted
     * permission for notifications to be sent.
     *
     * @return {boolean}
     */
    private hasPermission (): boolean {
        return Notification.permission === GRANTED;
    }

    /**
     * Determines if the user has denied permission
     * to send notifications.
     *
     * @return {boolean}
     */
    private hasDeniedPermission (): boolean {
        return Notification.permission === DENIED;
    }

    /**
     * Requests permission to send notifications
     */
    private getPermission (): void {
        Notification.requestPermission()
            .then((permission) => {
                this.shouldSendNotifications = permission === GRANTED;
            })
            .catch((err) => {
                console.log('An error occurred requesting permission for desktop notifications', err);
            });
    }

    /**
     * Notifies the user.
     *
     * @param {NotificationArguments} options - An optional object with other information to pass to the notification constructor
     */
    public notify (options: NotificationArguments): void {
        // Check that we should be sending notifications or not
        if (this.shouldSendNotifications) {
            // Get arguments
            const { title, onClick, ...args } = options;

            // Spawn the notification
            const icon = this.icon;
            const notification = new Notification(title, { icon, ...args });

            // Bind the onClick event if one was passed.
            if (onClick) {
                notification.onclick = onClick;
            }

            // Close the notification after 5 seconds.
            // Some browsers will handle this automatically
            // after a certain amount of time, but some don't,
            // so we will force close it eventually.
            setTimeout(() => {
                notification.close();
            }, 5000);
        }
    }
}
