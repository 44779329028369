import {Injectable} from '@angular/core';
import {Contracts} from '../../contracts/services/user';
import {User} from '../../models/user';
import {ApiService} from '../api-service';
import {Paginator} from '../../core/paginator/paginator';
import {PaginationData} from '../../core/paginator/pagination-data';
import {JwtService} from '../jwt.service';
import {UpdatePasswordRequest} from '../../contracts/requests/update-password.request';
import {AuthenticatableUser} from '../../models/authenticatable-user';
import {Timezone} from '../../models/timezone';
import {SearchUsersRequest} from '../../contracts/requests/search-users.request';
import {SaveUserRequest} from '../../contracts/requests/save-user.request';
import {AuthEventBus} from '../../events/auth/auth.event.bus';
import {identifiers} from '../../helpers/other';
import {DeleteResponse} from '../../contracts/responses/delete.response';
import { RestoreResponse } from '../../contracts/responses/restore.response';
import { TokenResponse } from '../../contracts/responses/token.response';
import { flatMap } from 'rxjs/operators';

@Injectable()
export class UserService implements Contracts.User {

    /**
     * Constructs the class.
     *
     * @param {ApiService} api
     * @param {JwtService} jwt
     * @param {AuthEventBus} authEvents
     */
    constructor(
        protected api: ApiService,
        protected jwt: JwtService,
        protected authEvents: AuthEventBus
    ) {}

    /**
     * Get all of the users.
     *
     * @return { Promise<User[]> }
     */
    public all(): Promise<User[]> {
        return this.api.get('users')
            .toPromise()
            .catch(this.api.handleError);
    }

    /**
     * Get a paginated list of users.
     *
     * @param {number} perPage
     * @param {number} page
     * @return { Promise<Paginator> }
     */
    public paginate(perPage: number, page: number): Promise<Paginator<User>> {
        return this.api.get<PaginationData<User>>('users?per_page=' + perPage + '&page=' + page)
            .toPromise()
            .then(res => new Paginator(res))
            .catch(this.api.handleError);
    }

    /**
     * Get a paginated list of archived users.
     *
     * @param {number} perPage
     * @param {number} page
     * @return { Promise<Paginator> }
     */
    public paginateArchived(perPage: number, page: number): Promise<Paginator<User>> {
        return this.api.get<PaginationData<User>>('users/archived?per_page=' + perPage + '&page=' + page)
            .toPromise()
            .then(res => new Paginator(res))
            .catch(this.api.handleError);
    }

    /**
     * Find a user by their identifier.
     *
     * @param {string} identifier
     * @return { Promise<User> }
     */
    public find(identifier: string): Promise<User> {
        return this.api.get<User>('users/' + identifier)
            .toPromise()
            .catch(this.api.handleError);
    }

    /**
     * Find a user by their token.
     *
     * @param {string} token
     * @returns {Promise<User>}
     */
    public findByToken(token: string): Promise<User> {
        return this.api.get<User>('authenticate/user/' + token)
            .toPromise();
            // .catch((error) => {
            //     if (error.status == 401 || error.status == 400) {
            //         return this.api.newToken()
            //             .pipe(flatMap((response) => {
            //                 return this.jwt.set(response['token'])
            //                     .pipe(flatMap((newToken: string) => {
            //                         return this.api.get('authenticate/user/' + newToken);
            //                     }));
            //             }));
            //     }
            //
            //     return this.api.handleError(error);
            // });
    }

    /**
     * Create a new user and then return the new user model.
     *
     * @param {User} user
     * @returns {Promise<User>}
     */
    public create(user: User): Promise<User> {
        return this.api.post<User>('users', this.getUserData(user))
            .toPromise()
            .catch(this.api.handleError);
    }

    /**
     * Update a user, and then return the updated user model.
     *
     * @param {User} user
     * @returns {Promise<User>}
     */
    public update(user: User): Promise<User> {
        const userData = this.getUserData(user);

        // We use a separate endpoint to update a user's password so we don't
        // pass it through with this request.
        delete userData.password;
        delete userData.password_confirmation;

        return this.api.put<User>('users/' + user.identifier + '/update', userData)
            .toPromise()
            .catch(this.api.handleError);
    }

    /**
     * Get the data required to persist a user.
     *
     * @param {User} user
     * @returns {SaveUserRequest}
     */
    private getUserData({ roles, companies, permissions, ...user }: User): SaveUserRequest {
        return {
            ...user,
            roles: identifiers(roles),
            companies: identifiers(companies),
            permissions: Object.keys(permissions).reduce((acc, permission) => {
                if (permissions[permission]) {
                    acc.push(permission);
                }

                return acc;
            }, []),
        };
    }

    /**
     * Update a user's password, and then return the updated user model.
     *
     * @param {User} user
     * @param {UpdatePasswordRequest} request
     * @returns {Observable<R>}
     */
    public updatePassword(user: User, request: UpdatePasswordRequest): Promise<User> {
        return this.api.put<User>('users/' + user.identifier + '/update/password', request)
            .toPromise()
            .catch(this.api.handleError);
    }

    /**
     * Delete a user.
     *
     * @param {User} user
     * @returns {Promise<TResult>}
     */
    public delete(user: User): Promise<boolean> {
        return this.api.delete<DeleteResponse>('users/' + user.identifier + '/destroy')
            .toPromise()
            .then(res => res.deleted)
            .catch(this.api.handleError);
    }

    /**
     * Restore a user.
     *
     * @param {User} user
     * @returns {Promise<TResult>}
     */
    public restore(user: User): Promise<boolean> {
        return this.api.put<RestoreResponse>('users/' + user.identifier + '/restore', {})
            .toPromise()
            .then(res => res.restored)
            .catch(this.api.handleError);
    }

    /**
     * Set the user's timezone to the provided timezone.
     *
     * @param user
     * @param timezone
     * @returns {Promise<TResult|TResult2|User>}
     */
    public setTimezone(user: AuthenticatableUser, timezone: Timezone): Promise<User> {
        const request = {
            timezone: timezone.identifier
        };

        return this.api.post<User>('users/timezone', request)
            .toPromise()
            .catch(this.api.handleError);
    }

    /**
     * Save the preferences against the provided user.
     *
     * @param user
     * @param preferences
     * @returns {Promise<User>}
     */
    public savePreferences(preferences: any): Promise<User> {
        const request = { preferences };

        return this.api.post<User>('users/preferences', request)
            .toPromise()
            .catch(this.api.handleError);
    }

    /**
     * Save the users's contact details
     */
    public saveContactDetails(request: ContactDetailsRequest): Promise<User> {
        return this.api.post<User>('users/contact-details', request)
            .toPromise()
            .catch(this.api.handleError);
    }

    /**
     * Search all of the users and return the response as a paginator.
     *
     * @param {SearchUsersRequest} request
     * @returns {Promise<Paginator>}
     */
    public search(request: SearchUsersRequest): Promise<Paginator<User>> {
        return this.api.post<PaginationData<User>>('users/search', request)
            .toPromise()
            .then(res => new Paginator(res))
            .catch(this.api.handleError);
    }

    /**
     * Search all of the users.
     *
     * @param {SearchUsersRequest} request
     * @returns {Promise<User[]>}
     */
    public searchAll(request: SearchUsersRequest): Promise<User[]> {
        return this.api.post<User[]>('users/search', request)
            .toPromise()
            .then(res => res)
            .catch(this.api.handleError);
    }

    /**
     * Impersonate the given user.
     *
     * @param {User} user
     * @returns {Promise<string>}
     */
    public impersonate(user: User): Promise<string> {
        return this.makeImpersonateRequest(user.identifier);
    }

    /**
     * Stop impersonating a user.
     *
     * @return {Promise<string>}
     */
    public stopImpersonating(): Promise<string> {
        return this.makeImpersonateRequest('stop');
    }

    /**
     * Makes an impersonate request and handle the token response.
     *
     * @param {string} endpoint - The endpoint to request. Should
     *                            be a user identifier or 'stop'.
     */
    private makeImpersonateRequest(endpoint: string): Promise<string> {
        return this.api.get<TokenResponse>(`authenticate/impersonate/${endpoint}`)
            .toPromise()
            .then(({ token }) => {
                this.authEvents.impersonate.emit();
                this.jwt.set(token);

                return token;
            })
            .catch(this.api.handleError);
    }
}
