import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { BreadcrumbComponent } from "../../components/breadcrumbs/breadcrumb.component";
import { PageHeaderComponent } from "../../components/page-header/page-header.component";
import { PreloaderComponent } from "../../components/preloader/preloader.component";
import { PaginationComponent } from "../../components/pagination/pagination.component";
import { CommentsComponent } from "../../components/comments/comments.component";
import { ToggleComponent } from "../../components/toggle/toggle.component";
import { JoinedListComponent } from "../../components/joined-list/joined-list.component";
import { AnimationsModule } from "./animations.module";
import { CurrencySelectorComponent } from "../../components/currency-selector/currency-selector.component";
import { HttpClientModule } from "@angular/common/http";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgxTableComponent } from "../../components/ngx-table/ngx-table.component";
import { NgxTdDirective } from "../../components/ngx-table/ngx-td.directive";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { IgnorePipe } from "../../pipes/ignore.pipe";
import { NgxCarpenterModule } from "@kestrel-saas/ngx-carpenter";
import { BootstrapFormsModule } from "../bootstrap/bootstrap-forms.module";
import { ToFixedPipe } from "../../pipes/to-fixed/to-fixed.pipe";
import { SafeHtmlPipe } from "../../pipes/safe-html.pipe";
import { RecipientSelectorComponent } from "../../components/recipient-selector/recipient-selector.component";
import { RecipientSelectorContactComponent } from "../../components/recipient-selector/recipient-selector-contact/recipient-selector-contact.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
        AnimationsModule,
        BootstrapFormsModule,
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        NgxCarpenterModule
    ],
    declarations: [
        PageHeaderComponent,
        BreadcrumbComponent,
        CommentsComponent,
        ToggleComponent,
        JoinedListComponent,
        CurrencySelectorComponent,
        NgxTableComponent,
        NgxTdDirective,
        IgnorePipe,
        PaginationComponent,
        PreloaderComponent,
        ToFixedPipe,
        SafeHtmlPipe,
        RecipientSelectorComponent,
        RecipientSelectorContactComponent
    ],
    exports: [
        CommonModule,
        HttpClientModule,
        RouterModule,
        AnimationsModule,
        BootstrapFormsModule,
        ModalModule,
        TooltipModule,
        NgxCarpenterModule,
        PageHeaderComponent,
        BreadcrumbComponent,
        PaginationComponent,
        CommentsComponent,
        ToggleComponent,
        JoinedListComponent,
        CurrencySelectorComponent,
        NgxTableComponent,
        NgxTdDirective,
        IgnorePipe,
        PreloaderComponent,
        ToFixedPipe,
        SafeHtmlPipe,
        RecipientSelectorComponent,
        RecipientSelectorContactComponent
    ],
    providers: [IgnorePipe]
})
export class SharedModule {}
