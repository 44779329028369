<div bsModal #appUpdatedModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="appUpdatedModal" aria-hidden="true" [config]="{backdrop: 'static', keyboard: 'Disable'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-body text-center">
                <h3>Whilst you've been working, we've been working too</h3>
                <p>Please refresh your browser to get the latest updates.</p>
                <a (click)="refresh()" class="btn btn-primary">Refresh</a>
            </div>
        </div>
    </div>
</div>