import { Component, OnInit } from '@angular/core';
import { BannerBarService } from '../../services/banner-bar/banner-bar.service';
import { UserService } from '../../services/users/user.service';
import { AuthenticatableUser } from '../../models/authenticatable-user';
import { Action } from '../../models/banner-bar/action';
import { Banner } from '../../models/banner-bar/banner';
import {Router} from '@angular/router';

@Component({
    selector: 'banner-bar',
    templateUrl: './templates/banner-bar.html',
})
export class BannerBarComponent implements OnInit {
    /**
     * Array of banners to show
     */
    public banners: Banner[] = [];

    constructor (
        protected bannerBarService: BannerBarService,
        protected userService: UserService,
        protected router: Router
    ) {}

    ngOnInit (): void {
        const promises: [Promise<boolean>, Promise<AuthenticatableUser>] = [
            this.bannerBarService.impersonating(),
            this.bannerBarService.user(),
        ];

        Promise.all(promises)
            .then(([impersonating, user]) => {
                if (impersonating) {
                    const banner = this.getImpersonationBanner(user);
                    this.banners.push(banner);
                }
            });
    }

    /**
     * Handles all action clicks and passes through
     * to the specified handler, if one exists.
     *
     * @param {Event} e The click event
     * @param {Action} action The action that was triggered
     */
    public actionClicked (e: Event, action: Action): void {
        if (action.onClick) {
            action.onClick(e);
        }
    }

    /**
     * Gets the impersonation banner.
     *
     * @param {AuthenticatableUser} user
     * @return {Banner}
     */
    protected getImpersonationBanner (user: AuthenticatableUser): Banner {
        return {
            text: `You are impersonating ${user.full_name}`,
            class: 'environment-banner-primary',
            actions: [{
                text: 'Stop Impersonating',
                href: '/stop-impersonating',
                onClick: (e: Event) => {
                    e.preventDefault();
                    this.userService.stopImpersonating()
                        .then(() => {
                            window.location.href = '/';
                        })
                        .catch(err => console.dir(err));
                },
            }],
        } as Banner;
    }
}
