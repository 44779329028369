import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { Router } from "@angular/router";
import { AppComponent } from "../components/app/app.component";
import { RavenErrorHandler } from "../handlers/raven-error-handler";
import { AppRoutingModule } from "./app-routing.module";
import { EchoModule } from "./shared/echo.module";
import { DateFormatterService } from "../services/dates/date-formatter.service";
import { SessionStorageService } from "../services/storage/session-storage.service";
import { SweetAlertDriver } from "../services/notifier/drivers/sweet-alert";
import { SharedDataService } from "../services/shared-data/shared-data.service";
import { ApiService } from "../services/api-service";
import { BaseDriver } from "../services/notifier/drivers/base-driver";
import { CdnService } from "../services/cdn-service";
import { Notifier } from "../services/notifier/notifier";
import { Renderer } from "../services/notifications/renderer";
import { NotificationService } from "../services/notifications/notification.service";
import { SystemModule } from "./shared/system.module";
import { LoginModule } from "./login/login.module";
import { VersionService } from "../services/versions/version.service";
import { AppVersionEventBus } from "../events/system/app-version.event.bus";
import { CheckForUpdatesGuard } from "../guards/check-for-updates.guard";
import { LogAccessGuard } from "../guards/log-access.guard";
import { AccessLogService } from "../services/access-log/access-log.service";
import { GdprModule } from "./gdpr/gdpr.module";
import { PathStorageService } from "../services/storage/path-storage.service";
import {
    NoopAnimationsModule,
    BrowserAnimationsModule
} from "@angular/platform-browser/animations";
import { LocalStorageService } from "../services/storage/local-storage.service";
import { GroupService } from "../services/groups/group.service";
import { BROWSER_GLOBAL_PROVIDERS } from "../utilities/window-ref";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { EffectsModule } from "@ngrx/effects";
import { environment } from "../../environments/environment";
import { reducers, metaReducers } from "../store";
import { MFApiService } from "../services/mfapi-service";
import { HttpClientModule } from "@angular/common/http";
import { AngularEditorModule } from "@kolkov/angular-editor";
import * as Sentry from "@sentry/angular-ivy";

@NgModule({
    imports: [
        BrowserModule,
        AppRoutingModule,
        EchoModule,
        SystemModule,
        LoginModule,
        GdprModule,
        NoopAnimationsModule,
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true
            }
        }),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        EffectsModule.forRoot([]),
        BrowserAnimationsModule,
        HttpClientModule,
        AngularEditorModule
    ],
    declarations: [AppComponent],
    providers: [
        ApiService,
        CdnService,
        DateFormatterService,
        MFApiService,
        NotificationService,
        Notifier,
        Renderer,
        SessionStorageService,
        LocalStorageService,
        SharedDataService,
        VersionService,
        AccessLogService,
        AppVersionEventBus,
        CheckForUpdatesGuard,
        LogAccessGuard,
        { provide: BaseDriver, useClass: SweetAlertDriver },
        // { provide: ErrorHandler, useClass: RavenErrorHandler },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        PathStorageService,
        GroupService,
        ...BROWSER_GLOBAL_PROVIDERS
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
