import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '../components/login/login.component';
import { SystemComponent } from '../components/system/system.component';
import { GdprComponent } from '../components/gdpr/gdpr.component';
import { CheckForUpdatesGuard } from '../guards/check-for-updates.guard';
import { LogAccessGuard } from '../guards/log-access.guard';
import { CanAccessModuleGuard } from '../guards/can-access-module.guard';

const routes: Routes = [
    {
        path: '',
        component: SystemComponent,
        canActivate: [
            LogAccessGuard,
            CheckForUpdatesGuard,
        ],
        children: [
            {
                path: '',
                loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
                pathMatch: 'full',
            },
            {
                path: 'preferences',
                loadChildren: () => import('./preferences/preferences.module').then(m => m.PreferencesModule),
            },
            {
                path: 'notification-history',
                loadChildren: () => import('./notification-history/notification-history.module').then(m => m.NotificationHistoryModule),
            },
            {
                path: 'address-book',
                loadChildren: () => import('./address-book/address-book.module').then(m => m.AddressBookModule),
                canLoad: [
                    CanAccessModuleGuard,
                ],
                data: {
                    module: 'crm'
                }
            },
            {
                path: 'opportunities',
                loadChildren: () => import('./opportunities/opportunities.module').then(m => m.OpportunitiesModule),
                canLoad: [
                    CanAccessModuleGuard,
                ],
                data: {
                    module: 'crm'
                }
            },
            {
                path: 'leads',
                loadChildren: () => import('./leads/leads.module').then(m => m.LeadsModule),
                canLoad: [
                    CanAccessModuleGuard,
                ],
                data: {
                    module: 'crm'
                }
            },
            {
                path: 'sales-queue',
                loadChildren: () => import('./sales-queue/sales-queue.module').then(m => m.SalesQueueModule),
                canLoad: [
                    CanAccessModuleGuard,
                ],
                data: {
                    module: 'crm'
                }
            },
            {
                path: 'booking-queue',
                loadChildren: () => import('./booking-queue/booking-queue.module').then(m => m.BookingQueueModule),
                canLoad: [
                    CanAccessModuleGuard,
                ],
                data: {
                    module: 'crm'
                }
            },
            {
                path: 'rate-lookup',
                loadChildren: () => import('./rate-lookup/rate-lookup.module').then(m => m.RateLookupModule),
                canLoad: [
                    CanAccessModuleGuard,
                ],
                data: {
                    module: 'rms'
                }
            },
            {
                path: 'rate-import',
                loadChildren: () => import('./rate-import/rate-import.module').then(m => m.RateImportModule),
                canLoad: [
                    CanAccessModuleGuard,
                ],
                data: {
                    module: 'rms'
                }
            },
            {
                path: 'rates',
                loadChildren: () => import('./rates/rates.module').then(m => m.RatesModule),
                canLoad: [
                    CanAccessModuleGuard,
                ],
                data: {
                    module: 'rms'
                }
            },
            {
                path: 'rates-awaiting-approval',
                loadChildren: () => import('./rates/rates-awaiting-approval.module').then(m => m.RatesAwaitingApprovalModule),
                canLoad: [
                    CanAccessModuleGuard,
                ],
                data: {
                    module: 'rms'
                }
            },
            {
                path: 'expiring-rates',
                loadChildren: () => import('./rates/expiring-rates.module').then(m => m.ExpiringRatesModule),
                canLoad: [
                    CanAccessModuleGuard,
                ],
                data: {
                    module: 'rms'
                }
            },
            {
                path: 'feeders',
                loadChildren: () => import('./feeders/feeders.module').then(m => m.FeedersModule),
                canLoad: [
                    CanAccessModuleGuard,
                ],
                data: {
                    module: 'rms'
                }
            },
            {
                path: 'feeders-awaiting-approval',
                loadChildren: () => import('./feeders/feeders-awaiting-approval.module').then(m => m.FeedersAwaitingApprovalModule),
                canLoad: [
                    CanAccessModuleGuard,
                ],
                data: {
                    module: 'rms'
                }
            },
            {
                path: 'expiring-feeders',
                loadChildren: () => import('./feeders/expiring-feeders.module').then(m => m.ExpiringFeedersModule),
                canLoad: [
                    CanAccessModuleGuard,
                ],
                data: {
                    module: 'rms'
                }
            },
            {
                path: 'sailing-schedules',
                loadChildren: () => import('./sailing-schedules/sailing-schedules.module').then(m => m.SailingSchedulesModule),
                canLoad: [
                    CanAccessModuleGuard,
                ],
                data: {
                    module: 'ssm'
                }
            },
            {
                path: 'expiring-voyages',
                loadChildren: () => import('./sailing-schedules/expiring-voyages.module').then(m => m.ExpiringVoyagesModule),
                canLoad: [
                    CanAccessModuleGuard,
                ],
                data: {
                    module: 'ssm'
                }
            },
            {
                path: 'documents',
                loadChildren: () => import('./documents/documents.module').then(m => m.DocumentsModule),
                canLoad: [
                    CanAccessModuleGuard,
                ],
                data: {
                    module: 'intranet'
                }
            },
            {
                path: 'document-types',
                loadChildren: () => import('./document-types/document-types.module').then(m => m.DocumentTypesModule),
                canLoad: [
                    CanAccessModuleGuard,
                ],
                data: {
                    module: 'intranet'
                }
            },
            {
                path: 'ports',
                loadChildren: () => import('./ports/ports.module').then(m => m.PortsModule),
            },
            {
                path: 'providers',
                loadChildren: () => import('./providers/providers.module').then(m => m.ProvidersModule),
            },
            {
                path: 'charges',
                loadChildren: () => import('./charges/charges.module').then(m => m.ChargesModule),
            },
            {
                path: 'movement-types',
                loadChildren: () => import('./movement-types/movement-types.module').then(m => m.MovementTypesModule),
            },
            {
                path: 'client-types',
                loadChildren: () => import('./client-types/client-types.module').then(m => m.ClientTypesModule),
            },
            {
                path: 'bill-of-lading',
                loadChildren: () => import('./bills-of-lading/bills-of-lading.module').then(m => m.BillsOfLadingModule),
            },
            {
                path: 'commodities',
                loadChildren: () => import('./commodities/commodities.module').then(m => m.CommoditiesModule),
            },
            {
                path: 'tariffs',
                loadChildren: () => import('./tariffs/tariffs.module').then(m => m.TariffsModule),
            },
            {
                path: 'groups',
                loadChildren: () => import('./groups/groups.module').then(m => m.GroupsModule),
            },
            {
                path: 'companies',
                loadChildren: () => import('./companies/companies.module').then(m => m.CompaniesModule),
            },
            {
                path: 'mailboxes',
                loadChildren: () => import('./mailboxes/mailboxes.module').then(m => m.MailboxesModule),
            },
            {
                path: 'users',
                loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
            },
            {
                path: 'roles',
                loadChildren: () => import('./roles/roles.module').then(m => m.RolesModule),
            },
            {
                path: 'lead-types',
                loadChildren: () => import('./lead-types/lead-types.module').then(m => m.LeadTypesModule),
            },
        ],
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'gdpr',
        loadChildren: () => import('./gdpr/gdpr.module').then(m => m.GdprModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
}
