// Lib
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

// Modules
import { SharedModule } from '../shared/shared.module';
import { GdprRoutingModule } from './gdpr-routing.module';

// Services
import { GdprService } from '../../services/gdpr/gdpr.service';

// Components
import { GdprComponent } from '../../components/gdpr/gdpr.component';
import { GdprPreferencesComponent } from '../../components/gdpr/gdpr-preferences.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        GdprRoutingModule,
        ReactiveFormsModule,
    ],
    providers: [
        GdprService,
    ],
    declarations: [
        GdprComponent,
        GdprPreferencesComponent,
    ],
})
export class GdprModule {
    //
}
