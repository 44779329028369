import {ApiService} from "../api-service";
import {Injectable} from "@angular/core";
import {Group} from "../../models/group";

@Injectable()
export class TenantService {
    constructor(protected apiService: ApiService) {}

    /**
     * Switch the tenant we are using, returns a new JWT for our
     * the current user.
     */
    public switch(group: Group): Promise<string> {
        return this.apiService.post('tenants/switch', {group_id: group.identifier})
            .toPromise()
            .then(response => response['token']);
    }
}