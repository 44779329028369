import { ActivatedRouteSnapshot, GuardsCheckStart, Route, Router, RouterStateSnapshot } from '@angular/router';
import {VersionService} from '../services/versions/version.service';
import {AppVersion} from '../contracts/app-version';
import {AppVersionEventBus} from '../events/system/app-version.event.bus';
import {Injectable} from '@angular/core';
import * as moment from 'moment';
import { JwtService } from '../services/jwt.service';
import { filter } from 'rxjs/operators';

@Injectable()
export class CheckForUpdatesGuard  {

    /**
     * The application version from the last check.
     */
    protected appVersion: AppVersion;

    /**
     * When we last checked the application version.
     */
    protected lastChecked: any;

    /**
     * The previously visited url. This allows to check if the just the
     * fragment has changed.
     */
    protected previousUrl: string;

    constructor(
        private versionService: VersionService,
        private router: Router,
        private appVersionEventBus: AppVersionEventBus,
        private jwt: JwtService,
    ) {
        // The canActivate, canAccessChild, and canLoad guards are only
        // ran the first time that route is accessed and then it caches
        // the result. We want to check the version every time a route
        // is loaded so we listen for the router events instead and then
        // handle checking the application version.
        router.events
            .pipe(filter(event => event instanceof GuardsCheckStart))
            .subscribe((event: GuardsCheckStart) => {
                const date = moment();
                const parts = event.urlAfterRedirects.toString().split('/');
                let segment = parts[1] || '';
                segment = segment.split('?')[0] || segment;
                segment = segment.split('#')[0] || segment;

                // We only want to check for updates if the full url has changed,
                // not if only the fragment has changed. Here we check if the
                // current url is different to the previous url, if it isn't then
                // we short circuit.
                if (this.previousUrl && segment == this.previousUrl) {
                    return;
                }

                // If we don't have a JWT in storage, we should short circuit, because
                // the server will error if no valid token is provided.
                if (!this.jwt.get()) {
                    return;
                }

                // Only check the application version once a minute.
                if ( ! this.lastChecked || date > this.lastChecked) {
                    this.versionService.latest()
                        .then((appVersion) => {
                            if (!this.appVersion) {
                                this.appVersion = appVersion;
                            } else {
                                if (this.appVersion.web_app != appVersion.web_app) {
                                    this.appVersionEventBus.webApp.emit(appVersion.web_app);
                                }
                            }

                            return true;
                        });

                    this.lastChecked = date.add(1, 'second');
                }

                this.previousUrl = segment;
            });
    }

    /**
     * Always return true as we do our checking based on router events.
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {boolean}
     */
    public canActivate (
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean {
        return true;
    }

    /**
     * Always return true as we do our checking based on router events.
     *
     * @param {ActivatedRouteSnapshot} childRoute
     * @param {RouterStateSnapshot} state
     * @returns {boolean}
     */
    public canActivateChild (
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean {
        return true;
    }

    /**
     * Always return true as we do our checking based on router events.
     *
     * @param {Route} route
     * @returns {boolean}
     */
    public canLoad (route: Route): boolean {
        return true;
    }

}
