import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[animateLoading]'
})

export class AnimateLoadingDirective implements OnChanges {

    @Input() animateLoading: boolean;

    constructor(private el: ElementRef, private renderer: Renderer2) {
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (this.animateLoading) {
            this.renderer.addClass(this.el.nativeElement, 'animate-loading');
        } else {
            this.renderer.removeClass(this.el.nativeElement, 'animate-loading');
        }

        this.el.nativeElement.disabled = this.animateLoading;
    }
}
