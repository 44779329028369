import { Injectable } from '@angular/core';

import { ApiService } from '../api-service';
import { HttpClient } from '@angular/common/http';
import { JwtService } from '../jwt.service';
import { ObservableInput } from 'rxjs';

@Injectable()
export class AccessLogService extends ApiService {

    constructor(protected jwtService: JwtService, protected http: HttpClient) {
        super(jwtService, http);
    }

    /**
     * Log the users access to the provided path, if a path is not provided
     * then use the current url.
     *
     * @param {string} path
     * @returns {Promise<null>}
     */
    public log(path: string = null): Promise<null> {
        const data = {url: path || this.getUrl()};

        // Short circuit if no JWT is available, because the
        // server will only error if not provided a valid token.
        if (!this.jwtService.get()) {
            return Promise.resolve(null);
        }

        return this.post('log', data)
            .toPromise()
            .then(this.noop)
            .catch(this.noop);
    }

    /**
     * Get the current url.
     *
     * @returns {string}
     */
    private getUrl(): string {
        return window.location.href;
    }

    /**
     * Return null.
     *
     * @returns {null}
     */
    private noop(): null {
        return null;
    }

    /**
     * Catch the error while generating a new token.
     *
     * @param error
     * @returns {ObservableInput<any>}
     */
    public catchNewToken(error: any): ObservableInput<any> {
        return error;
    }

}
