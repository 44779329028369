// Lib
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Services
import { GdprService } from '../../services/gdpr/gdpr.service';
import { Notifier } from '../../services/notifier/notifier';

@Component({
    selector: 'gdpr',
    templateUrl: './templates/gdpr.html',
})
export class GdprComponent implements OnInit {
    /**
     * Flag to toggle loader.
     */
    public loader = true;

    /**
     * Flag to determine if the use clicked 'no'.
     */
    public optedOut = false;

    /**
     * The UUID from the route.
     */
    protected uuid: string;

    /**
     * The signature from the route.
     */
    protected signature: string;

    /**
     * Constructs the class.
     *
     * @param {ActivatedRoute} route
     * @param {GdprService} gdpr
     * @param {Notifier} notifier
     * @param {Router} router
     */
    constructor (
        protected route: ActivatedRoute,
        protected gdpr: GdprService,
        protected notifier: Notifier,
        protected router: Router,
    ) {}

    /**
     * Initiates the class.
     */
    public ngOnInit (): void {
        this.adjustDocumentHeight();

        const params = this.route.snapshot.paramMap;
        this.uuid = params.get('uuid');
        this.signature = params.get('signature');

        this.route.fragment.subscribe((fragment) => {
            if (fragment === 'no') {
                this.savePreferences(false);
            } else {
                this.savePreferences(true);
            }
        });
    }

    /**
     * Saves the users choice on button click.
     *
     * @param {boolean} continueReceiving
     */
    public savePreferences (continueReceiving: boolean): void {
        const { uuid, signature } = this;

        this.loader = true;

        if ( ! continueReceiving) {
            this.gdpr.disableMarketing(uuid, signature)
                .then(() => {
                    this.optedOut = true;
                    this.loader = false;
                })
                .catch(() => {
                    this.unknownError();
                });

            return;
        }

        this.gdpr.enableMarketing(uuid, signature)
            .then(() => {
                const url = `/gdpr/${uuid}/preferences/${signature}`;
                this.router.navigateByUrl(url);
            })
            .catch((err) => {
                if (err.status === 422) {
                    this.loader = false;
                    this.invalidSignature();
                } else {
                    this.unknownError();
                }
            });
    }

    /**
     * Shows an 'invalid signature' error message.
     */
    protected invalidSignature () {
        this.notifier.error({
            title: 'An Error Occured',
            body: 'An invalid URL signature was provided. Please contact technical support.',
        });
    }

    /**
     * Shows an 'unknown error' error message.
     */
    protected unknownError () {
        this.notifier.error({
            title: 'An Error Occured',
            body: 'Something went wrong, please try again. If the problem persists, contact the technical support team.',
        });
    }

    /**
     * Give the <html /> and <body /> tags 100% height.
     * The notifier causes the page to shift around because
     * it adjusts the html/body height, so we add on these
     * styles which have the highest specificity.
     */
    protected adjustDocumentHeight () {
        const { body, documentElement: html } = document;
        html.style.height = '100%';
        body.style.height = '100%';
    }
}
