import {Injectable} from '@angular/core';
import {ApiService} from '../api-service';
import {Observable} from 'rxjs';

@Injectable()
export class ValidationService {

    constructor(private apiService: ApiService) {}

    /**
     * Check if there is an existing record that matches the table field
     * and post data.
     *
     * @param {string} table
     * @param {string} field
     * @param body
     * @returns {Observable<Object>}
     */
    public exists(table: string, field: string, body: any): Observable<Object> {
        return this.apiService.post('validation/' + table + '/' + field + '/exists', body);
    }

}
