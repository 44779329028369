import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../services/auth/auth.service';
import {TimezoneService} from '../../services/timezone/timezone.service';
import {Timezone} from '../../models/timezone';
import {TimezoneGroup} from '../../models/timezone-group';
import {UserService} from '../../services/users/user.service';
import {AuthenticatableUser} from '../../models/authenticatable-user';
import {Notifier} from '../../services/notifier/notifier';
import {User} from '../../models/user';
import { ReplyEmailModalComponent } from '../emails/reply-email-modal.component';

@Component({
    selector: 'timezone-selector',
    templateUrl: './templates/timezone-selector.html'
})
export class TimezoneSelectorComponent implements OnInit {

    /**
     * The timezone we have guessed the user is currently in.
     */
    public guessedTimezone: Timezone;

    /**
     * All of the available timezone groups.
     */
    public groups: TimezoneGroup[];

    /**
     * The currently signed in user.
     */
    public user: AuthenticatableUser;

    /**
     * A flag indicating whether the user is selecting a timezone.
     *
     * @type {boolean}
     */
    public selectingTimezone = false;

    /**
     * The currently selected timezone group identifier.
     *
     * @type {string}
     */
    public selectedGroupIdentifier = '';

    /**
     * The currently selected timezone group.
     */
    public selectedGroup: null|TimezoneGroup;

    /**
     * The currently selected timezone identifier.
     *
     * @type {string}
     */
    public selectedTimezoneIdentifier = '';

    /**
     * The currently selected timezone.
     */
    public selectedTimezone: null|Timezone;

    /**
     * The timezone selector modal instance.
     */
    @ViewChild('timezoneSelectorModal', {static: false}) public timezoneSelectorModal: any;

    constructor(
        protected authService: AuthService,
        protected timezoneService: TimezoneService,
        protected userService: UserService,
        protected notifier: Notifier
    ) {}

    ngOnInit(): void {
        this.authService.user().then((user) => {
            this.user = user;

            if ( ! user.preferences['timezone']) {
                const promises: Array<Promise<any>> = [];

                promises.push(
                    this.timezoneService.guessTimezone()
                        .then((guessedTimezone) => this.guessedTimezone = guessedTimezone)
                        .catch((error: any) => {
                            this.selectTimezone();
                        })
                );

                promises.push(
                    this.timezoneService.groups().then((groups) => this.groups = groups)
                );

                Promise.all(promises).then(() => {
                    this.timezoneSelectorModal.show();
                });
            }
        });
    }

    /**
     * Change to the select timezone view.
     */
    public selectTimezone(): void {
        this.selectingTimezone = true;
    }

    /**
     * Set the currently selected group.
     */
    public setGroup(): void {
        this.selectedGroup = null;

        this.groups.forEach((group) => {
            if (group.identifier == this.selectedGroupIdentifier) {
                this.selectedGroup = group;
                this.selectedTimezoneIdentifier = '';
                this.selectedTimezone = null;
            }
        });
    }

    /**
     * Set the currently selected timezone.
     */
    public setTimezone(): void {
        this.selectedTimezone = null;

        if (this.selectedGroup) {
            this.selectedGroup.timezones.forEach((timezone) => {
                if (timezone.identifier == this.selectedTimezoneIdentifier) {
                    this.selectedTimezone = timezone;
                }
            });
        }
    }

    /**
     * Update the user's timezone to the guessed timezone, then update
     * the currently signed in user's preferences.
     */
    public confirmTimezone(): void {
        if (this.guessedTimezone) {
            this.userService.setTimezone(this.user, this.guessedTimezone).then((user: User) => {
                this.timezoneUpdatedSuccessfully(user);
            });
        }
    }

    /**
     * Update the user's timezone to the selected timezone, then update
     * the currently signed in user's preferences.
     */
    public saveTimezone(): void {
        if (this.selectedTimezone) {
            this.userService.setTimezone(this.user, this.selectedTimezone).then((user: User) => {
                this.timezoneUpdatedSuccessfully(user);
            });
        }
    }

    /**
     * Handle the timezone being updated successfully.
     *
     * @param user
     */
    protected timezoneUpdatedSuccessfully(user: User): void {
        this.authService.setUser(user);

        this.timezoneSelectorModal.hide();
        this.notifier.success({
            title: 'Timezone Set',
            body: 'Your timezone was set successfully'
        });
    }
}
