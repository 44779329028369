import {Module} from './module';
import {Company} from './company';
import {Image} from './image';
import {Provider} from './providers';
import { Agent } from './agent';
import { Tariff } from './tariff';
import { BillOfLading } from './bill-of-lading';

export class Group {
    identifier?: string;
    agent_id?: string;
    name: string;
    domain: string;
    database?: string;
    phone?: string;
    fax?: string;
    email: string;
    website?: string;
    company_number?: string;
    vat_number?: string;
    asset_path?: string;
    modules?: Module[];
    companies?: Company[];
    tariffs?: Tariff[];
    logo?: Image;
    providers?: Provider[];
    billsOfLading?: BillOfLading[];
    agent?: Agent;
    agents?: Agent[];
    mappedModules?: string[];

    constructor(attributes: any) {
        Object.keys(attributes).forEach(key => {
            this[key] = attributes[key];
        });
    }

    /**
     * Check if the group has access to the module..
     */
    public has?(module: string): boolean {
        if ( ! this.mappedModules) {
            this.mappedModules = this.modules.map((m) => m.name);
        }

        return this.mappedModules.includes(module);
    }
}
