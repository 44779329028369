import {Injectable} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {AuthenticatableUser} from '../../models/authenticatable-user';
import * as moment from 'moment';

@Injectable()
export class DateFormatterService {

    /**
     * The currently signed in user.
     */
    private currentUser: AuthenticatableUser;

    constructor(private authService: AuthService) {
        this.authService.user().then((user) => {
            this.currentUser = user;
        });
    }

    /**
     * Format the provided moment object to a string in the current user's
     * date format.
     *
     * @param {Moment} moment
     * @param {AuthenticatableUser} user
     * @return {string}
     */
    public format(momentInstance: moment.Moment, user: AuthenticatableUser = null): string {
        return momentInstance.format(this.getDateFormat(user));
    }

    /**
     * Convert the date string to a moment object.
     *
     * @param {string} date
     * @param {AuthenticatableUser|null} user
     * @returns {Moment}
     */
    public toMoment(date: string, user: AuthenticatableUser = null): moment.Moment {
        return moment(date, this.getDateFormat(user));
    }

    /**
     * Get the user's date format.
     *
     * @param {AuthenticatableUser|null} user
     * @returns {string}
     */
    public getDateFormat(user: AuthenticatableUser = null): string {
        const currentUser = user || this.currentUser;

        return currentUser.getDateFormat(true);
    }

}
