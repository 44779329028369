import {Injectable} from '@angular/core';


import {User} from '../../models/user';
import {UserService} from '../users/user.service';
import {ApiService} from '../api-service';
import {JwtService} from '../jwt.service';
import {AuthenticatableUser} from '../../models/authenticatable-user';
import {SharedDataService} from '../shared-data/shared-data.service';
import {HttpClient} from '@angular/common/http';
import {AuthEventBus} from '../../events/auth/auth.event.bus';
import { TokenResponse } from '../../contracts/responses/token.response';

@Injectable()
export class AuthService extends ApiService {

    /**
     * The current user.
     */
    protected currentUser: AuthenticatableUser;

    constructor(
        protected jwtService: JwtService,
        protected userService: UserService,
        protected http: HttpClient,
        protected authEventBus: AuthEventBus,
        protected sharedDataService: SharedDataService
    ) {
        super(jwtService, http);

        // Flush the shared data service and the current user when a new
        // user signs in.
        this.authEventBus.login.subscribe(() => {
            this.sharedDataService.remove();
            this.currentUser = null;
        });
    }

    /**
     * Check if a user is signed in.
     *
     * @returns {Promise<boolean>}
     */
    public async check(): Promise<boolean> {
        const token = this.jwtService.get();

        return token ? this.jwtService.check() : false;
    }

    /**
     * Attempt to authenticate a user. If successful update the JWT
     * token in the cookie.
     *
     * @param {string} email
     * @param {string} password
     * @returns {Promise<boolean>}
     */
    public authenticate(email: string, password: string): Promise<boolean> {
        return this.post<TokenResponse>('authenticate', { email, password })
            .toPromise()
            .then(({ token }) => {
                this.updateToken(token.trim());

                return true;
            })
            .catch(this.handleError);
    }

    /**
     * Get the current user.
     *
     * @returns {AuthenticatableUser}
     */
    public async user(): Promise<AuthenticatableUser> {
        if ( ! this.currentUser) {
            const user = await this.sharedDataService.get<User>('user');

            if (user.identifier) {
                this.setUser(user);
            }
        }

        return this.currentUser;
    }

    /**
     * Get the current JWT.
     *
     * @returns {string}
     */
    public token(): string {
        return this.jwtService.get();
    }

    /**
     * Update the current JWT in the cookie.
     *
     * @param token
     * @returns {boolean}
     */
    public updateToken(token: string): true {
        this.jwtService.set(token);

        return true;
    }

    /**
     * Set the current user.
     *
     * @param user
     */
    public setUser(user: User): void {
        this.currentUser = new AuthenticatableUser(user, user.permissions);
    }

    /**
     * Unset the current user.
     */
    public forgetUser(): void {
        this.currentUser = null;
    }

}
