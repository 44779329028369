import { AfterViewInit, Component, OnDestroy } from "@angular/core";

declare const $: any;

@Component({
    selector: "auth",
    templateUrl: "./auth.component.html",
    styleUrls: ["./auth.component.css"]
})
export class AuthComponent implements AfterViewInit, OnDestroy {
    ngAfterViewInit(): void {
        $(function() {
            $("body").vegas({
                slides: [
                    { src: "/assets/img/slide-2.jpg" },
                    { src: "/assets/img/slide-1.jpg" },
                    { src: "/assets/img/slide-3.jpg" },
                    { src: "/assets/img/slide-4.jpg" },
                    { src: "/assets/img/slide-5.jpg" }
                ],
                overlay: false,
                delay: 6000,
                animation: "vegas-transition-fade2"
            });
        });
    }

    ngOnDestroy(): void {
        $("body").vegas("destroy");
    }
}
