import { Subscription } from "rxjs";
import { OnDestroy, Directive } from "@angular/core";

@Directive()
export class HasSubscriptions implements OnDestroy {
    /**
     * The registered subscriptions.
     *
     * @type {any[]}
     */
    public subscriptions: Subscription[] = [];

    ngOnDestroy(): void {
        this.unsubscribe();
    }

    /**
     * Register a new subscription.
     *
     * @param {Subscription} subscription
     */
    public addSubscription(subscription: Subscription): void {
        this.subscriptions.push(subscription);
    }

    /**
     * Unsubscribe all of the registered subscriptions.
     */
    public unsubscribe(): void {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }
}
